import React from 'react';
import MessageView from 'app/components/MessageView';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

const ErrorMessageView = () => {
	const { t } = useTranslation();

	return (
		<MessageView
			icon="error"
			title={t('confirmation:error:title')}
			message={[t('confirmation:error:message')]}
			footer={
				<Link color="inherit" className="font-medium" href="/">
					{t('Refresh')}
				</Link>
			}
		/>
	);
};

export default ErrorMessageView;
