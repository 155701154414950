import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// use title defined in HTML head as the site name
const siteName = document.title;

const usePageTitle = (title?: string) => {
	// pageTitle is used in loading component which is outside of Suspense...
	// ...The easiest solution is to just manually wait for the translation to show it.
	const { t, ready } = useTranslation('pageTitle', { useSuspense: false });
	const pageTitle = title && ready ? `${t(title)} | ${siteName}` : siteName;
	useEffect(() => {
		document.title = pageTitle;
	}, [pageTitle]);
};

export default usePageTitle;
