/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { responseError, responseErrors, getFormsUrl } from 'app/utils/helpers';
import { getProfile } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Form } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_SELECTED_FORM_SUCCESS = 'GET_SELECTED_FORM_SUCCESS';
export const SET_VIEW_FORM = 'SET_VIEW_FORM';
export const SET_EXPORT_FORM = 'SET_EXPORT_FORM';
export const SET_EDIT_ALERT = 'SET_EDIT_ALERT';
export const SET_REMOVE_DIALOG = 'SET_REMOVE_DIALOG';
export const SET_REMOVE_ALERT = 'SET_REMOVE_ALERT';

export const addForms = (formIds: string[]) => {};

export const getForms = (): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const response = await axios.get(`${formsApiUrl}/api/forms`);
		// const response = await axios.get(`/api/forms`);
		console.log('response from api/forms', response);
		// const forms = getFormsDataHack(response.data);
		// dispatch(appActions.setForms(forms));
	} catch (error) {
		dispatch(appActions.handleError(error, 'forms:get:fail'));
	}
};

// export const getSelectedForm = (formId: string): AppThunk => async (dispatch, getState) => {
// 	try {
// 		const {
// 			data: { formData }
// 		} = await axios.get(`https://sc53mpvt09.execute-api.us-east-1.amazonaws.com/dev/api/forms/${formId}`);
// 		console.log('id, data', formId, formData);

// 		dispatch({
// 			type: GET_SELECTED_FORM_SUCCESS,
// 			payload: {
// 				formId,
// 				formData
// 			}
// 		});
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const addFormsToFormGroup = (formIds: string[], formGroupId: string | undefined): AppThunk => async (
	dispatch,
	getState
) => {
	const body = {
		formGroup: {
			id: formGroupId
		}
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const responses = await Promise.all(
			formIds.map(formId => axios.patch(`${formsApiUrl}/api/forms/changeformgroup/${formId}`, body))
		);
		if (responseErrors(responses).length) {
			dispatch(
				appActions.alert(formIds.length > 1 ? 'forms:move group:fail' : 'form:move group:fail', 'warning')
			);
		} else {
			dispatch(
				appActions.alert(formIds.length > 1 ? 'forms:move group:success' : 'form:move group:success', 'success')
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms', 'form groups']));
	} catch (error) {
		dispatch(appActions.handleError(error, formIds.length > 1 ? 'forms:move group:fail' : 'form:move group:fail'));
	}
};

// Need to update for creating form
export const createForm = ({
	title,
	formDefinition,
	cssDefinition
}: {
	title: string;
	formDefinition: Form['formDefinition'];
	cssDefinition: Form['cssDefinition'];
}): AppThunk => async (dispatch, getState) => {
	const data = {
		title,
		formDefinition,
		cssDefinition
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const response = await axios.put(`${formsApiUrl}/api/forms`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form:create:fail', 'warning'));
		} else {
			dispatch(appActions.alert('form:create:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:create:fail'));
	}
};

export const editForm = ({
	id,
	title,
	valid,
	metadataKeys,
	formDefinition,
	cssDefinition
}: Partial<Form>): AppThunk => async (dispatch, getState) => {
	const data = {
		title,
		valid,
		metadataKeys,
		formDefinition,
		cssDefinition
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const response = await axios.put(`${formsApiUrl}/api/forms/${id}`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form:update:fail', 'warning'));
		} else {
			dispatch(appActions.alert('form:update:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:update:fail'));
	}
};

export const renameForm = ({ id, title }: Partial<Form>): AppThunk => async (dispatch, getState) => {
	const data = {
		title
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const response = await axios.patch(`${formsApiUrl}/api/forms/rename/${id}`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form:rename:fail', 'warning'));
		} else {
			dispatch(appActions.alert('form:rename:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:rename:fail'));
	}
};

export const removeForms = (formIds: string[]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const responses = await Promise.all(formIds.map(formId => axios.delete(`${formsApiUrl}/api/forms/${formId}`)));
		if (responseErrors(responses).length) {
			dispatch(appActions.alert(formIds.length > 1 ? 'forms:remove:fail' : 'form:remove:fail', 'warning'));
		} else {
			dispatch(appActions.alert(formIds.length > 1 ? 'forms:remove:success' : 'form:remove:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, formIds.length > 1 ? 'forms:remove:fail' : 'form:remove:fail'));
	}
};

export const publishForms = (formIds: string[]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const responses = await Promise.all(
			formIds.map(formId => axios.patch(`${formsApiUrl}/api/forms/publish/${formId}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert(formIds.length > 1 ? 'forms:publish:fail' : 'form:publish:fail', 'warning'));
		} else {
			dispatch(
				appActions.alert(formIds.length > 1 ? 'forms:publish:success' : 'form:publish:success', 'success')
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, formIds.length > 1 ? 'forms:publish:fail' : 'form:publish:fail'));
	}
};

export const unpublishForms = (formIds: string[]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const responses = await Promise.all(
			formIds.map(formId => axios.patch(`${formsApiUrl}/api/forms/unpublish/${formId}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert(formIds.length > 1 ? 'forms:unpublish:fail' : 'form:unpublish:fail', 'warning'));
		} else {
			dispatch(
				appActions.alert(formIds.length > 1 ? 'forms:unpublish:success' : 'form:unpublish:success', 'success')
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, formIds.length > 1 ? 'forms:unpublish:fail' : 'form:unpublish:fail'));
	}
};

export const cloneForms = (formId: string, formGroup: Form['formGroup']): AppThunk => async (dispatch, getState) => {
	const data = {
		formGroup
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		const response = await axios.post(`${formsApiUrl}/api/forms/clone/${formId}`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form:clone:fail', 'warning'));
		} else {
			dispatch(appActions.alert('form:clone:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:clone:fail'));
	}
};

export const importSampleForm = (formId: string, formGroup?: Form['formGroup']): AppThunk => async (
	dispatch,
	getState
) => {
	const data = {
		formGroup
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		// DEV NOTE::using `fetch` to avoid axios headers causing CORS issues
		const fileResponse = await fetch(`${process.env.PUBLIC_URL}/assets/sample-forms/${formId}.zip`);

		if (!fileResponse.ok) {
			throw new Error('Network response was not ok');
		}

		const blob = await fileResponse.blob();
		const file = new Blob([blob], { type: 'application/zip' });

		const url = `${formsApiUrl}/api/archive`;
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to import sample form', 'warning'));
		} else {
			dispatch(appActions.alert('sample form imported', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'failed to import sample form'));
	}
};

export const exportForms = (formIds: string[]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const ids = formIds.join(',');
		const url = `${formsApiUrl}/api/archive?ids=${ids}${
			localStorage.getItem('exportFormsNoEncrypt') ? '&noenc=true' : ''
		}`;
		const response = await axios.get(url, {
			responseType: 'blob',
			headers: {
				Accept: 'application/zip'
			}
		});

		const downloadUrl = URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', 'forms.zip');
		document.body.appendChild(link);
		link.click();
		link.remove();
		dispatch(appActions.alert('form:export:success', 'success'));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:export:fail'));
	}
};

export const importForms = (file: any): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const url = `${formsApiUrl}/api/archive`;
		const formData = new FormData();
		formData.append('file', file);

		const response = await axios.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form:import:fail', 'error'));
		} else {
			dispatch(appActions.alert('form:import:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'form:import:fail'));
	}
};
