import React from 'react';
import { Redirect } from 'react-router-dom';

const ManagedLicenseGroupsPageConfig = {
	settings: {
		layout: {
			config: {
				// DEV NOTE::is this right or wrong?
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/manager/tenants',
			exact: true,
			component: React.lazy(() => import('./ManagedLicenseGroupsPage'))
		},
		{
			path: '/manager',
			exact: true,
			component: () => <Redirect to="/manager/tenants" />
		}
	]
};

export default ManagedLicenseGroupsPageConfig;
