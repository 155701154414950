const helpContextDefinitions = [
	{
		stratusPath: '/admin',
		helpUrl: '/tenants/sec.tenants/'
	},
	{
		stratusPath: '/admin/dashboard',
		helpUrl: '/tenants/sec.dashboard/'
	},
	{
		stratusPath: '/admin/devices',
		helpUrl: '/tenants/sec.device-management/'
	},
	{
		stratusPath: '/admin/forms',
		helpUrl: '/tenants/sec.form-management/'
	},
	{
		stratusPath: '/admin/home',
		helpUrl: '/tenants/sec.home/'
	},
	{
		stratusPath: '/admin/home/inbox',
		helpUrl: '/tenants/sec.inbox/'
	},
	{
		stratusPath: '/admin/internalform',
		helpUrl: '/collection/sec.internal-form/#using-a-form'
	},
	{
		stratusPath: '/admin/home/job-queue',
		helpUrl: '/tenants/sec.job-queue/'
	},
	{
		stratusPath: '/admin/home/job-queue/job-queue-management',
		helpUrl: '/tenants/sec.job-queue/'
	},
	{
		stratusPath: '/admin/home/jobs',
		helpUrl: '/tenants/sec.jobs/'
	},
	{
		stratusPath: '/admin/home/jobs/job/',
		helpUrl: '/tenants/sec.jobs/#job'
	},
	{
		stratusPath: '/admin/home/tracker',
		helpUrl: '/tenants/sec.tracker/'
	},
	{
		stratusPath: '/admin/logs',
		helpUrl: '/tenants/sec.logs/'
	},
	{
		stratusPath: '/admin/profile',
		helpUrl: '/getstart/sec.profile/'
	},
	{
		stratusPath: '/admin/settings',
		helpUrl: '/getstart/sec.settings'
	},
	{
		stratusPath: '/admin/roles',
		helpUrl: '/tenants/sec.roles/'
	},
	{
		stratusPath: '/admin/users',
		helpUrl: '/tenants/sec.user-management/'
	},
	{
		stratusPath: '/admin/workflows',
		helpUrl: '/workflows/sec.workflow-management/'
	},
	{
		stratusPath: '/profile',
		helpUrl: '/getstart/sec.profile/'
	}
];

export default helpContextDefinitions;
