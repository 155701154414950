import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import NavbarMobileToggleFab from 'app/fuse-layouts/shared-components//NavbarMobileToggleFab';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { getHideNavbar } from 'app/store/reducers';
import NavbarLayoutAdmin from './NavbarLayoutAdmin';
import NavbarMobileLayoutAdmin from './NavbarMobileLayoutAdmin';

const navbarWidth = 280;

const useStyles = makeStyles(theme => ({
	navbar: {
		display: 'flex',
		overflow: 'hidden',
		height: 64,
		minHeight: 64,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		zIndex: 6
	},
	navbarMobile: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		width: navbarWidth,
		minWidth: navbarWidth,
		height: '100%',
		zIndex: 4,
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter
		}),
		boxShadow: theme.shadows[3]
	}
}));

function NavbarWrapperLayoutAdmin({ adminPage, ...props }) {
	const location = useLocation();
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbarTheme = useSelector(({ fuse }) => fuse.settings.navbarTheme);
	const navbar = useSelector(({ fuse }) => fuse.navbar);

	const hideNavbar = useSelector(getHideNavbar);

	const classes = useStyles(props);

	const userPage = /^\/user(\/.*)?/.test(location.pathname);

	if (adminPage || userPage) {
		return (
			<>
				<ThemeProvider theme={navbarTheme}>
					<Hidden smDown>
						<Paper className={clsx(classes.navbar)} square>
							<NavbarLayoutAdmin />
						</Paper>
					</Hidden>

					<Hidden mdUp>
						<Drawer
							anchor="left"
							variant="temporary"
							open={navbar.mobileOpen}
							classes={{
								paper: classes.navbarMobile
							}}
							onClose={_ev => dispatch(Actions.navbarCloseMobile())}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}
						>
							<NavbarMobileLayoutAdmin />
						</Drawer>
					</Hidden>
				</ThemeProvider>

				{config.navbar.display && !hideNavbar && !config.toolbar.display && (
					<Hidden mdUp>
						<NavbarMobileToggleFab />
					</Hidden>
				)}
			</>
		);
	}

	// member-side navbar will go here
	return null;
}

export default React.memo(NavbarWrapperLayoutAdmin);
