import axios from 'app/client';
import { AppThunk } from 'app/store';
import { getSelectedLicenseGroupId, getProfile } from 'app/store/reducers';
import { MpForm } from 'app/store/types';
import * as appActions from './app.actions';

const massageRawMpForms = (rawMpForm: any) => {
	const mpForm: MpForm = {
		id: rawMpForm.id,
		serial: rawMpForm.serial,
		name: rawMpForm.name,
		friendlyName: rawMpForm.friendlyName || undefined,
		family: rawMpForm.family,
		generation: rawMpForm.generation,
		productLine: rawMpForm.productLine,
		licenseGroupId: rawMpForm.tenantId,
		publicKey: rawMpForm.publicKey
	};

	return mpForm;
};

export const GET_MP_FORMS_SUCCESS = 'GET_MP_FORMS_SUCCESS';

export const getMpForms = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const { data: rawMpForms } = await axios.get(`/api/v1/user/${licenseGroupId}/${profile.id}/forms`);

		const mpForms: MpForm[] = rawMpForms.map(massageRawMpForms);

		const validMpForms = mpForms.filter(({ generation }) => ['IT5', 'IT6'].includes(generation));

		dispatch({
			type: GET_MP_FORMS_SUCCESS,
			payload: {
				list: validMpForms
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
