import React from 'react';

const AccountPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/success',
			exact: true,
			component: React.lazy(() => import('./SuccessPage'))
		}
	]
};

export default AccountPageConfig;
