/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, convertToFormData, responseErrors } from 'app/utils/helpers';
import { getApps, getAppsById, getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { App } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'app-delete-failed',
	'no-associated-app',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-app-authorization',
	'failed-to-get-apps',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-app-group-failed',
	'app-group-delete-failed'
];

export const addApp = (data: {
	type: App['type'];
	name: App['name'];
	description: App['description'];
	iconFile: File | null;
	url: App['url'] | null;
	workflowId: App['workflowId'] | null;
	nodeId: App['nodeId'] | null;
}): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	const { iconFile, ...restData } = data;

	try {
		const response = await axios.post('/api/app', restData);
		const appId = response.data.id;
		if (iconFile) {
			try {
				const iconResponse = await axios.post(
					`/api/app/${appId}/icon`,
					{ file: iconFile },
					{
						transformRequest: convertToFormData,
						headers: { 'Content-Type': 'multipart/form-data' }
					}
				);
				dispatch(appActions.alert('app added', 'success'));
			} catch (error) {
				console.error(error);
				dispatch(appActions.alert('failed to upload app icon', 'warning'));
			}
		} else {
			dispatch(appActions.alert('app added', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps']));
	} catch (error) {
		console.error(error);
		dispatch(appActions.alert('failed to add app', 'error'));
	}
};

export const editApp = (
	appId: App['id'],
	data: {
		type: App['type'];
		name: App['name'];
		description: App['description'];
		iconFile: File | null;
		url: App['url'] | null;
		workflowId: App['workflowId'] | null;
		nodeId: App['nodeId'] | null;
	}
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	const { iconFile, ...restData } = data;

	try {
		if (Object.values(restData).filter(Boolean).length) {
			const response = await axios.patch(`/api/app/${appId}`, restData);
		}
		if (iconFile) {
			try {
				const iconResponse = await axios.post(
					`/api/app/${appId}/icon`,
					{ file: iconFile },
					{
						transformRequest: convertToFormData,
						headers: { 'Content-Type': 'multipart/form-data' }
					}
				);
				dispatch(appActions.bumpAppIconCache(appId));
				dispatch(appActions.alert('app edited', 'success'));
			} catch (error) {
				console.error(error);
				dispatch(appActions.alert('failed to upload app icon', 'warning'));
			}
		} else {
			dispatch(appActions.alert('app edited', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps']));
	} catch (error) {
		console.error(error);
		dispatch(appActions.alert('failed to edit app', 'error'));
	}
};

export const shareApp = (
	ids: App['id'][],
	{
		users,
		userGroups,
		devices,
		deviceGroups
	}: {
		users: { [userId: string]: { role: App['acl']['users'][0]['role'] } };
		userGroups: { [userGroupId: string]: { role: App['acl']['groups'][0]['role'] } };
		devices?: { [deviceId: string]: { role: App['acl']['devices'][0]['role'] } };
		deviceGroups?: { [deviceGroupId: string]: { role: App['acl']['deviceGroups'][0]['role'] } };
	}
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await Promise.all(
			ids.map(id => {
				const prevAcl = getAppsById(state)[id].acl;
				const acl = {
					...prevAcl,
					users: [
						...Object.entries(users).map(([userId, { role }]) => ({
							name: userId,
							role
						})),
						// also include the owner
						prevAcl.users.find(user => user.role === 'owner')!
					],
					groups: Object.entries(userGroups).map(([userGroupId, { role }]) => ({
						name: userGroupId,
						role
					}))
					// devices: devices
					// 	? Object.entries(devices).map(([deviceId, { role }]) => ({
					// 			name: deviceId,
					// 			role
					// 	  }))
					// 	: [],
					// deviceGroups: deviceGroups
					// 	? Object.entries(deviceGroups).map(([deviceGroupId, { role }]) => ({
					// 			name: deviceGroupId,
					// 			role
					// 	  }))
					// 	: []
				};
				return axios.patch(`/api/app/${ids[0]}/share`, { acl });
			})
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to share app', 'warning'));
			return true;
		}
		dispatch(appActions.alert('app shared', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps']));
		return true;
	} catch (error) {
		dispatch(appActions.handleError(error));
	}

	return false;
};

export const removeLicenseFromApps = (ids: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/app/${ids[0]}/license`, { licensed: false });
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('app:unassign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('app:unassign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'app:unassign license:fail'));
	}
};

export const assignLicenseToApps = (ids: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/app/${ids[0]}/license`, { licensed: true });
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('app:assign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('app:assign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'app:assign license:fail'));
	}
};

export const removeApps = (ids: App['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		delete: ids
	};
	try {
		const response = await axios.delete(`/api/app?ids=${ids.join(',')}`);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'apps'], () => {
				if (err.includes('no-associated-app')) {
					dispatch(
						appActions.alert(ids.length > 1 ? 'app:remove:not found' : 'app:remove:not found', 'warning')
					);
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert(ids.length > 1 ? 'apps:remove:fail' : 'app:remove:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(ids.length > 1 ? 'apps:remove:success' : 'app:remove:success', 'success')
					);
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, ids.length > 1 ? 'apps:remove:fail' : 'app:remove:fail'));
	}
};

export const addAppsToAppGroup = (appIds: string[], appGroupId: string | undefined): AppThunk => async (
	dispatch,
	getState
) => {
	const appsById = getAppsById(getState());

	try {
		const data = appIds.map(appId => {
			return {
				id: appId,
				add: [appGroupId],
				delete: appsById[appId].appGroupId
			};
		});
		const response = await axios.patch(`/api/app/app-group`, { apps: data });
		const err: any = findErrors(response);
		if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
			dispatch(appActions.alert(appIds.length > 1 ? 'apps:move group:fail' : 'app:move group:fail', 'warning'));
		} else {
			dispatch(
				appActions.alert(appIds.length > 1 ? 'apps:move group:success' : 'app:move group:success', 'success')
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['apps', 'app groups']));
	} catch (error) {
		dispatch(appActions.handleError(error, appIds.length > 1 ? 'apps:move group:fail' : 'app:move group:fail'));
	}
};
