/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId, getDevices } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Device, DeviceGroup } from 'app/store/types';
import { UNGROUPED_DEVICE_GROUP_ID } from 'app/utils/default-groups';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'no-associated-device',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-device-authorization',
	'failed-to-get-devices',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-device-group-failed',
	'device-group-delete-failed'
];

export const moveDevicesToDeviceGroups = (payload: {
	devices: Array<{ id: Device['serial']; add?: DeviceGroup['id'][]; delete?: DeviceGroup['id'][] }>;
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const ungroupedDevices = getDevices(getState()).reduce((prev, device) => {
		if (typeof device.deviceGroupId === 'undefined' ? true : device.deviceGroupId.length === 0) {
			return [...prev, device.serial];
		}
		return prev;
	}, [] as string[]);
	const added: string[] = [];
	const deleted: string[] = [];
	const groups = new Set();
	payload.devices.forEach(device => {
		if (device?.add && device.add.length > 0) {
			added.push(device.id);
			device.add.forEach(group => {
				groups.add(group);
			});
		}
		if (device?.delete && device.delete.length > 0) {
			deleted.push(device.id);
			device.delete.forEach(group => {
				groups.add(group);
			});
		}
	});

	const flag = added.every(device => ungroupedDevices.includes(device));

	try {
		const response = await axios.patch(`/api/device/${licenseGroupId}/device-group`, payload);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices', 'device groups'], () => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					if ((added.length > 0 && deleted.length > 0) || (added.length > 0 && flag)) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:move groups:fail'
										: 'devices:move group:fail'
									: groups.size > 1
									? 'device:move groups:fail'
									: 'device:move group:fail',
								'warning'
							)
						);
					} else if (added.length > 0) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:add to groups:fail'
										: 'device:add to group:fail'
									: groups.size > 1
									? 'device:add to groups:fail'
									: 'device:add to group:fail',
								'warning'
							)
						);
					} else if (deleted.length > 0) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:remove from groups:fail'
										: 'devices:remove from group:fail'
									: groups.size > 1
									? 'device:remove from groups:fail'
									: 'device:remove from group:fail',
								'warning'
							)
						);
					}
				} else if (err.length === 0) {
					if ((added.length > 0 && deleted.length > 0) || (added.length > 0 && flag)) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:move groups:success'
										: 'devices:move group:success'
									: groups.size > 1
									? 'device:move groups:success'
									: 'device:move group:success',
								'success'
							)
						);
					} else if (added.length > 0) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:add to groups:success'
										: 'device:add to group:success'
									: groups.size > 1
									? 'device:add to groups:success'
									: 'device:add to group:success',
								'success'
							)
						);
					} else if (deleted.length > 0) {
						dispatch(
							appActions.alert(
								payload.devices.length > 1
									? groups.size > 1
										? 'devices:remove from groups:success'
										: 'devices:remove from group:success'
									: groups.size > 1
									? 'device:remove from groups:success'
									: 'device:remove from group:success',
								'success'
							)
						);
					}
				}
			})
		);
	} catch (error) {
		if ((added.length > 0 && deleted.length > 0) || (added.length > 0 && flag)) {
			dispatch(
				appActions.handleError(
					error,
					payload.devices.length > 1
						? groups.size > 1
							? 'devices:move groups:fail'
							: 'devices:move group:fail'
						: groups.size > 1
						? 'device:move groups:fail'
						: 'device:move group:fail'
				)
			);
		} else if (added.length > 0) {
			dispatch(
				appActions.handleError(
					error,
					payload.devices.length > 1
						? groups.size > 1
							? 'devices:add to groups:fail'
							: 'device:add to group:fail'
						: groups.size > 1
						? 'device:add to groups:fail'
						: 'device:add to group:fail'
				)
			);
		} else if (deleted.length > 0) {
			dispatch(
				appActions.handleError(
					error,
					payload.devices.length > 1
						? groups.size > 1
							? 'devices:remove from groups:fail'
							: 'devices:remove from group:fail'
						: groups.size > 1
						? 'device:remove from groups:fail'
						: 'device:remove from group:fail'
				)
			);
		}
	}
};

export const removeDevicesFromAllDeviceGroups = (serials: Device['serial'][]): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const devices = getDevices(state) || [];
	const groups = new Set();

	try {
		const payload = {
			devices: devices
				.filter(device => serials.includes(device.serial))
				.map(device => {
					device.deviceGroupId.forEach(id => {
						groups.add(id);
					});
					return { id: device.serial, delete: device.deviceGroupId, add: [UNGROUPED_DEVICE_GROUP_ID] };
				})
		};
		const response = await axios.patch(`/api/device/${licenseGroupId}/device-group`, payload);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices', 'device groups'], () => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(
						appActions.alert(
							serials.length > 1
								? groups.size > 1
									? 'devices:remove from all groups:fail'
									: 'devices:remove from group:fail'
								: groups.size > 1
								? 'device:remove from all groups:fail'
								: 'device:remove from group:fail',
							'warning'
						)
					);
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(
							serials.length > 1
								? 'devices:remove from all groups:success'
								: 'device:remove from all groups:success',
							'success'
						)
					);
				}
			})
		);
	} catch (error) {
		dispatch(
			appActions.handleError(
				error,
				serials.length > 1
					? groups.size > 1
						? 'devices:remove from all groups:fail'
						: 'devices:remove from group:fail'
					: groups.size > 1
					? 'device:remove from all groups:fail'
					: 'device:remove from group:fail'
			)
		);
	}
};

export const assignPolicyToDevices = (serials: string[], policyId: string | undefined): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const params: { deviceId: string; policyId: string | null }[] = [];
		serials.forEach(serial => {
			params.push({
				deviceId: serial,
				policyId: policyId ?? null
			});
		});
		const responses = await Promise.all(
			_.chunk(params, 25).map(payload => axios.patch(`/api/v1/device/${licenseGroupId}`, payload))
		);
		const err: any = findErrors(responses);
		if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
			dispatch(appActions.alert('failed to assign policy to some devices', 'warning'));
		} else if (err.length === 0) {
			dispatch(appActions.alert('policy assigned', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['devices']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'failed to assign policy to some devices'));
	}
};

export const removeLicenseFromDevices = (serials: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const devices = getDevices(state) || [];

	try {
		const responses = await axios.patch(`/api/device/${licenseGroupId}/device/remove-license`, {
			serial: serials[0],
			friendlyName:
				devices.find(x => x.serial === serials[0])?.friendlyName ||
				devices.find(x => x.serial === serials[0])?.name
		});
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('device:unassign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('device:unassign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'device:unassign license:fail'));
	}
};

export const assignLicenseToDevices = (serials: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const devices = getDevices(state) || [];

	try {
		const responses = await axios.patch(`/api/device/${licenseGroupId}/device/assign-license`, {
			serial: serials[0],
			friendlyName:
				devices.find(x => x.serial === serials[0])?.friendlyName ||
				devices.find(x => x.serial === serials[0])?.name
		});
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('device:assign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('device:assign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'device:assign license:fail'));
	}
};

export const addDevices = (serials: Device['serial'][], successFn?: Function): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const data = {
		add: serials
	};

	try {
		const response = await axios.patch(`/api/device/${licenseGroupId}`, data);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (err.includes('already-associated')) {
					dispatch(
						appActions.alert(
							serials.length > 1 ? 'devices:add:duplicate' : 'device:add:duplicate',
							'warning'
						)
					);
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert(serials.length > 1 ? 'devices:add:fail' : 'device:add:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(serials.length > 1 ? 'devices:add:success' : 'device:add:success', 'success')
					);
					if (successFn) successFn();
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, serials.length > 1 ? 'devices:add:fail' : 'device:add:fail'));
	}
};

export const editDevice = ({
	id,
	description
}: {
	id: Device['serial'];
	description: Device['description'];
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		device: id,
		description
	};
	try {
		const response = await axios.patch(`/api/device/${licenseGroupId}/device-info`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('device:description:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('device:description:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'device:description:fail'));
	}
};

export const removeDevices = (serials: Device['serial'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		delete: serials
	};
	try {
		const response = await axios.patch(`/api/device/${licenseGroupId}`, data);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (err.includes('no-associated-device')) {
					dispatch(
						appActions.alert(
							serials.length > 1 ? 'devices:remove:not found' : 'device:remove:not found',
							'warning'
						)
					);
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(
						appActions.alert(serials.length > 1 ? 'devices:remove:fail' : 'device:remove:fail', 'error')
					);
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(
							serials.length > 1 ? 'devices:remove:success' : 'device:remove:success',
							'success'
						)
					);
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, serials.length > 1 ? 'devices:remove:fail' : 'device:remove:fail'));
	}
};

export const importDevices = (
	devices: Array<{
		productLine: string;
		modelName: string;
		serial: string;
		password: string;
		friendlyName?: string;
		location?: string;
		groupIds: string[];
	}>
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const response = await axios.post(`/api/device/${licenseGroupId}`, { devices });
		console.log(response);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['devices'], () => {
				if (err.includes('already-associated')) {
					dispatch(appActions.alert('device:add:duplicate', 'warning'));
				} else if (err.includes('wrong-device')) {
					dispatch(appActions.alert('device:import:wrong-device', 'warning'));
				} else if (err.includes('wrong-model')) {
					dispatch(appActions.alert('device:import:wrong-model', 'warning'));
				} else if (err.includes('password-short')) {
					dispatch(appActions.alert('device:import:password-short', 'warning'));
				} else if (err.includes('password-long')) {
					dispatch(appActions.alert('device:import:password-long', 'warning'));
				} else if (err.includes('password-default')) {
					dispatch(appActions.alert('device:import:password-default', 'warning'));
				} else if (err.includes('serial-short')) {
					dispatch(appActions.alert('device:import:serial-short', 'warning'));
				} else if (err.includes('serial-long')) {
					dispatch(appActions.alert('device:import:serial-long', 'warning'));
				} else if (err.includes('serial-char')) {
					dispatch(appActions.alert('device:import:serial-char', 'warning'));
				} else if (err.includes('device-name-long')) {
					dispatch(appActions.alert('device:import:device-name-long', 'warning'));
				} else if (err.includes('device-location-long')) {
					dispatch(appActions.alert('device:import:device-location-long', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('device:add:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('device:add:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'device:add:fail'));
	}
};

// Returns license information about all users or just one user
export const getLicenseInformation = (userId: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.get(`/api/inputNode/licenses/${userId}`);
		console.log(response);
	} catch (error) {
		//
	}
};

// Records license use
export const addLicense = (
	userId: string,
	nodeType: string,
	workflowId: string,
	accountId: string,
	folderPath: string
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.patch(`/api/inputNode/add-license`, {
			userId,
			nodeType,
			workflowId,
			accountId,
			folderPath
		});
		console.log(response);
	} catch (error) {
		//
	}
};

// Records license removal
export const removeLicense = (
	userId: string,
	nodeType: string,
	workflowId: string,
	accountId: string,
	folderPath: string
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.patch(`/api/inputNode/remove-license`, {
			userId,
			nodeType,
			workflowId,
			accountId,
			folderPath
		});
		console.log(response);
	} catch (error) {
		//
	}
};

// Assigns licenses from tenant to a user
// User A can assign from Tenant to User B or to self
export const assignLicense = (userId: string, quantity: number, successFn?: Function): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.patch(`/api/inputNode/${licenseGroupId}/assign-license`, {
			userId,
			quantity
		});
		console.log(response);
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(appActions.alert('device:non-mfp:assign:success', 'success'));
			if (successFn) successFn();
		} else {
			dispatch(appActions.alert('device:non-mfp:assign:error', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('device:non-mfp:assign:error', 'warning'));
	}
};

// Unassigns licenses from user back to the tenant
// User A can only unassign their own licenses
export const unassignLicense = (userId: string, quantity: number, successFn?: Function): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.patch(`/api/inputNode/${licenseGroupId}/unassign-license`, {
			userId,
			quantity
		});
		console.log(response);
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(appActions.alert('device:non-mfp:unassign:success', 'success'));
			if (successFn) successFn();
		} else {
			dispatch(appActions.alert('device:non-mfp:unassign:error', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('device:non-mfp:transfer:error', 'warning'));
	}
};

// Transfers licenses from one user to another
// User A can only transfer their own license to another user
export const transferLicense = (
	fromUserId: string,
	toUserId: string,
	quantity: number,
	successFn?: Function
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	try {
		const response = await axios.patch(`/api/inputNode/${licenseGroupId}/transfer-license`, {
			fromUserId,
			toUserId,
			quantity
		});
		console.log(response);
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(appActions.alert('device:non-mfp:transfer:success', 'success'));
			if (successFn) successFn();
		} else {
			dispatch(appActions.alert('device:non-mfp:transfer:error', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('device:non-mfp:transfer:error', 'warning'));
	}
};
