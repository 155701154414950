import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { NotificationsState, NotificationsActionTypes } from 'app/store/types';
import _ from '@lodash';
import * as Actions from '../actions';
import { getRolePermissionCheck } from './entitlements.reducer';

const initialState: NotificationsState = {};

const notificationsReducer = (state = initialState, action: NotificationsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { notifications }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(notifications && { byId: notifications })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'notifications',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	notificationsReducer
);

// Selectors
export const getNotificationsById = ({ app: { selectedLicenseGroupId }, notifications }: RootState) =>
	notifications[selectedLicenseGroupId].byId;

export const getNotifications = createSelector([getNotificationsById], notificationsById =>
	Object.values(notificationsById)
);
