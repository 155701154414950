import axios from 'app/client';
import { AxiosError } from 'axios';
import React from 'react';
import JSzip from 'jszip';
import * as MessageActions from 'app/store/actions/fuse/message.actions';
import { AppThunk } from 'app/store';
import { getSelectedLicenseGroupData, getSelectedLicenseGroupId } from 'app/store/reducers';
import { PublicId } from 'app/store/types';
import { isRootUrl, isLicenseGroupUrl } from 'app/utils/helpers';
import * as appActions from './app.actions';
import * as profileActions from './profile.actions';

export const GET_SELECTED_LICENSE_GROUP_ID_BY_SLUG = 'GET_SELECTED_LICENSE_GROUP_ID_BY_SLUG';
export const SET_QUEUES_FILTER_TEXT = 'SET_QUEUES_FILTER_TEXT';
export const SET_USERS_FILTER_TEXT = 'SET_USERS_FILTER_TEXT';
export const SET_DEVICES_FILTER_TEXT = 'SET_DEVICES_FILTER_TEXT';
export const SET_APPS_FILTER_TEXT = 'SET_APPS_FILTER_TEXT';
export const SET_NOTIFICATIONS_FILTER_TEXT = 'SET_NOTIFICATIONS_FILTER_TEXT';
export const SET_JOB_NAMES_FILTER_TEXT = 'SET_JOB_NAMES_FILTER_TEXT';
export const SET_ROLES_FILTER_TEXT = 'SET_ROLES_FILTER_TEXT';
export const SET_LOGS_FILTER_TEXT = 'SET_LOGS_FILTER_TEXT';
export const SET_FORMS_FILTER_TEXT = 'SET_FORMS_FILTER_TEXT';
export const SET_WORKFLOWS_FILTER_TEXT = 'SET_WORKFLOWS_FILTER_TEXT';
export const SET_MANAGED_LICENSE_GROUPS_FILTER_TEXT = 'SET_MANAGED_LICENSE_GROUPS_FILTER_TEXT';
export const SET_LOG_SECTION = 'SET_LOG_SECTION';
export const SET_LOG_SUB_SECTION = 'SET_LOG_SUB_SECTION';
export const SET_LOG_DATE_OPTION = 'SET_LOG_DATE_OPTION';
export const SET_LOG_CUSTOM_DATES = 'SET_LOG_CUSTOM_DATES';
export const SET_LOG_LEVEL_OPTIONS = 'SET_LOG_LEVEL_OPTIONS';
export const HIDE_NAVBAR = 'HIDE_NAVBAR';
export const ACKNOWLEDGE_COOKIE_NAGBAR = 'ACKNOWLEDGE_COOKIE_NAGBAR';
export const SET_COOKIE_SETTINGS = 'SET_COOKIE_SETTINGS';
export const HIDE_EXPIRED_BANNER = 'HIDE_EXPIRED_BANNER';
export const HIDE_SUSPENDED_BANNER = 'HIDE_SUSPENDED_BANNER';
export const HIDE_EXPIRED_SCREEN = 'HIDE_EXPIRED_SCREEN';
export const BUMP_SITE_BACKGROUND_CACHE = 'BUMP_SITE_BACKGROUND_CACHE';
export const BUMP_APP_ICON_CACHE = 'BUMP_APP_ICON_CACHE';
export const GET_MODIFIED_TENANT_PRICING_SUCCESS = 'GET_MODIFIED_TENANT_PRICING_SUCCESS';

export const getSelectedLicenseGroupIdBySlug = (licenseGroupSlug: string): AppThunk => async (dispatch, getState) => {
	try {
		const {
			data: { id: licenseGroupId }
		} = await axios.get(`/api/tenants/slug/${licenseGroupSlug}`);

		dispatch({
			type: GET_SELECTED_LICENSE_GROUP_ID_BY_SLUG,
			payload: {
				selectedLicenseGroupId: licenseGroupId
			}
		});
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			// re-throw error for handling in <LicenseGroupPageWrapper /> if 404
			throw error;
		}
		dispatch(handleError(error));
	}
};

export const setQueuesFilterText = (text: string) => {
	return {
		type: SET_QUEUES_FILTER_TEXT,
		payload: {
			queuesFilterText: text
		}
	};
};

export const setUsersFilterText = (text: string) => {
	return {
		type: SET_USERS_FILTER_TEXT,
		payload: {
			usersFilterText: text
		}
	};
};

export const setDevicesFilterText = (text: string) => {
	return {
		type: SET_DEVICES_FILTER_TEXT,
		payload: {
			devicesFilterText: text
		}
	};
};

export const setAppsFilterText = (text: string) => {
	return {
		type: SET_APPS_FILTER_TEXT,
		payload: {
			appsFilterText: text
		}
	};
};

export const setNotificationsFilterText = (text: string) => {
	return {
		type: SET_NOTIFICATIONS_FILTER_TEXT,
		payload: {
			notificationsFilterText: text
		}
	};
};

export const setJobNamesFilterText = (text: string) => {
	return {
		type: SET_JOB_NAMES_FILTER_TEXT,
		payload: {
			jobNamesFilterText: text
		}
	};
};

export const setRolesFilterText = (text: string) => {
	return {
		type: SET_ROLES_FILTER_TEXT,
		payload: {
			rolesFilterText: text
		}
	};
};

export const setLogsFilterText = (text: string) => {
	return {
		type: SET_LOGS_FILTER_TEXT,
		payload: {
			logsFilterText: text
		}
	};
};

export const setFormsFilterText = (text: string) => {
	return {
		type: SET_FORMS_FILTER_TEXT,
		payload: {
			formsFilterText: text
		}
	};
};

export const setWorkflowsFilterText = (text: string) => {
	return {
		type: SET_WORKFLOWS_FILTER_TEXT,
		payload: {
			workflowsFilterText: text
		}
	};
};

export const setManagedLicenseGroupsFilterText = (text: string) => {
	return {
		type: SET_MANAGED_LICENSE_GROUPS_FILTER_TEXT,
		payload: {
			managedLicenseGroupsFilterText: text
		}
	};
};

export const setLogSection = (text: string) => {
	return {
		type: SET_LOG_SECTION,
		payload: {
			logSection: text
		}
	};
};

export const setLogSubSection = (text: string) => {
	return {
		type: SET_LOG_SUB_SECTION,
		payload: {
			logSubSection: text
		}
	};
};

export const setLogDateOption = (text: string) => {
	return {
		type: SET_LOG_DATE_OPTION,
		payload: {
			logDateOption: text
		}
	};
};

export const setLogCustomDates = (obj: any) => {
	return {
		type: SET_LOG_CUSTOM_DATES,
		payload: {
			logCustomDates: obj
		}
	};
};

export const setLogLevelOptions = (arr: string[]) => {
	return {
		type: SET_LOG_LEVEL_OPTIONS,
		payload: {
			logLevelOptions: arr
		}
	};
};

export const hideNavbar = (hide: boolean) => {
	return {
		type: HIDE_NAVBAR,
		payload: {
			hide
		}
	};
};

// alerts
type TAlert = 'success' | 'error' | 'info' | 'warning';
type TOptions = {
	autoHideDuration?: number;
	anchorOrigin?: {
		vertical: 'top' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
};

// DEV NOTE::do NOT pass a translation directly to `alert` - `alert` internally translates - instead add a translation to the `alert.json` namespace and just pass the key name.
// If you _do_ need to pass your own translation (say you also need to include a variable) as a workaround you can pass a wrapping component to skip `alert`'s internal translating (e.g. `alert(<>{t('myKey')}</>)`)
export const alert = (
	message: string | React.ReactElement,
	type?: TAlert,
	options: TOptions = {}
): AppThunk => dispatch => {
	const variant = type === 'success' ? undefined : type; // use regular alert look for success messages for now

	const defaultOptions = {
		autoHideDuration: 4500,
		anchorOrigin: {
			vertical: 'top', // top bottom
			horizontal: 'right' // left center right
		}
	};
	// DEV NOTE::this function is ultimately calling `FuseMessage` inside `@fuse/core/FuseMessage/FuseMessage.js`
	dispatch(
		MessageActions.showMessage({
			message,
			variant, // success error info warning null
			...defaultOptions,
			...options
		})
	);
};

export const acknowledgeCookieNagbar = () => {
	return {
		type: ACKNOWLEDGE_COOKIE_NAGBAR
	};
};

export const setCookieSettings = (cookieSettings: boolean) => {
	return {
		type: SET_COOKIE_SETTINGS,
		payload: {
			cookieSettings
		}
	};
};

export const hideExpiredBanner = () => {
	return {
		type: HIDE_EXPIRED_BANNER
	};
};

export const hideSuspendedBanner = () => {
	return {
		type: HIDE_SUSPENDED_BANNER
	};
};

export const hideExpiredScreen = () => {
	return {
		type: HIDE_EXPIRED_SCREEN
	};
};

export const bumpSiteBackgroundCache = () => {
	return {
		type: BUMP_SITE_BACKGROUND_CACHE
	};
};

export const bumpAppIconCache = (appId: string) => {
	return {
		type: BUMP_APP_ICON_CACHE,
		payload: {
			appId
		}
	};
};

// TODO::change all alerts to be worded correctly
// error handler
export const handleError = (error: any, msg: string = 'something went wrong'): AppThunk => dispatch => {
	console.error(error);

	// don't display error alert if the request was aborted (assumed to have been purposefully aborted)
	if (error.code === 'ECONNABORTED') {
		return;
	}

	if (error.response) {
		console.error(error.response.data);

		// if user no longer authenticated
		if (error.response.status === 401) {
			// the actions after this trigger a page change but this might help for the second before redirect
			dispatch(alert('user not authenticated', 'error'));

			// only throw to login if API call was to an "internal" endpoint
			if (isRootUrl(error.response.config.url) || isLicenseGroupUrl(error.response.config.url)) {
				// this will trigger a login if on a license group page
				dispatch({
					type: profileActions.GET_PROFILE_SUCCESS,
					payload: {
						data: undefined
					}
				});
				dispatch({
					type: profileActions.LOGGED_OUT_USER
				});
			}
			return;
		}
		// if (error.data && error.data.message) {
		// 	return dispatch(alert(error.data.message, 'error'));
		// }
		if (error.response.status === 403 || error.response.statusCode === 403) {
			dispatch(alert('permission denied', 'error'));
			return;
		}
		if (error.response.status === 409 || error.response.statusCode === 409) {
			if (error.response.data.detail === 'Running workflows cannot be deleted') {
				dispatch(alert('cannot delete running workflow', 'warning'));
			} else if (error.response.data.detail === 'Paused workflows cannot be deleted') {
				dispatch(alert('cannot delete paused workflow', 'warning'));
			} else if (error.response.data.message === 'role:name conflict') {
				dispatch(alert('role:create:name already exists', 'warning'));
			} else {
				dispatch(alert('user group same name', 'warning'));
			}
			return;
		}
	}
	dispatch(alert(msg, 'error'));
};

// export const errorLog = err => async dispatch => {
// 	try {
// 		await axios.post('/api/error-log', { error: err });
// 	} catch (error) {
// 		dispatch(handleError(error));
// 	}
// };

export const clearModifiedTenantPricing = (): AppThunk => async dispatch => {
	dispatch({
		type: GET_MODIFIED_TENANT_PRICING_SUCCESS,
		payload: {
			currentPrice: undefined,
			modifiedPrice: undefined
		}
	});
};

export const getModifiedTenantPricing = ({
	newPublicId,
	newCapacity
}: {
	newPublicId: PublicId;
	newCapacity: number;
}): AppThunk => async (dispatch, getState) => {
	const { id: tenantId, catalogPublicId: currentPublicId } = getSelectedLicenseGroupData(getState());

	try {
		const [
			{
				data: { total: currentTotal, currencyCode: currentCurrencyCode }
			},
			{
				data: { total: modifiedTotal, currencyCode: modifiedCurrencyCode }
			}
		] = await Promise.all([
			axios.get(`/api/mp/catalog/pricing/${tenantId}?publicId=${currentPublicId}`),
			axios.get(`/api/mp/catalog/pricing/${tenantId}?publicId=${newPublicId}&quantity=${newCapacity}`)
		]);
		dispatch({
			type: GET_MODIFIED_TENANT_PRICING_SUCCESS,
			payload: {
				currentPrice: {
					total: currentTotal,
					currency: currentCurrencyCode
				},
				modifiedPrice: {
					total: modifiedTotal,
					currency: modifiedCurrencyCode
				}
			}
		});
	} catch (error) {
		dispatch(handleError(error));
	}
};

// create file blob data from the File object(File API)
const getZipBlob = async (files: File[]) => {
	const date = new Date();
	const dateWithOffset = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	const zip = new JSzip();
	files.forEach(file => {
		zip.file(file.name, file, { date: dateWithOffset });
	});

	const blobdata = await zip.generateAsync({
		type: 'blob',
		compression: 'DEFLATE',
		compressionOptions: {
			level: 9
		}
	});
	const zipblob = new Blob([blobdata]);

	// For development and testing purpose
	// Download the zipped file
	// const elem = window.document.createElement('a');
	// elem.href = window.URL.createObjectURL(zipblob);
	// elem.download = 'files.zip';
	// elem.click();

	return zipblob;
};

export const sendCustomerFeedback = (comment: string, emails: string[], files: File[]): AppThunk => async (
	dispatch,
	getState
) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const payload = {
			comment,
			emails,
			tenantId,
			file:
				files.length > 0
					? {
							name: '',
							key: ''
					  }
					: null
		};

		if (payload.file) {
			const filename = 'files.zip';
			const zipBlob = await getZipBlob(files);

			const data = {
				feedbackFile: zipBlob
			};

			const response = await axios.postForm('/api/feedback/file', data, {
				headers: {
					// 'Content-Type': 'Content-Type: multipart/form-data',
					'x-dps-filename': filename
				}
			});
			payload.file.name = filename;
			payload.file.key = response.data.key;
		}

		await axios.post(`/api/feedback/email`, payload);
	} catch (error) {
		dispatch(appActions.alert('failed to send the customer feedback', 'error'));
	}
};

export const sendCustomerInquiry = (form: any): AppThunk => async (dispatch, getState) => {
	let licenseGroupId = getSelectedLicenseGroupId(getState());

	// HACK-ish::this page isn't wrapped in `LicenseGroupPageWrapper` so we might need to grab the `licenseGroupId` ourselves
	const selectedLicenseGroupSlug = window.location.host.match(/^(.+)\.tenant\./)?.[1];
	if (!licenseGroupId && selectedLicenseGroupSlug) {
		const {
			data: { id: selectedLicenseGroupId }
		} = await axios.get(`/api/tenants/slug/${selectedLicenseGroupSlug}`);
		licenseGroupId = selectedLicenseGroupId;
	}

	try {
		const payload = {
			form,
			tenantId: licenseGroupId
		};

		await axios.post('/api/contact', payload);
	} catch (error) {
		console.error('error:', error);
		dispatch(appActions.alert('failed to send the inquiry', 'error'));
	}
};
