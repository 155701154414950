/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { InputNode } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'no-associated-inputNode',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-inputNode-authorization',
	'failed-to-get-inputNodes',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-inputNode-group-failed',
	'inputNode-group-delete-failed'
];

export const removeLicenseFromInputNodes = (ids: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/device/${licenseGroupId}/device/remove-license`, {
			serial: ids[0]
		});
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['input nodes'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('inputNode:unassign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('inputNode:unassign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'inputNode:unassign license:fail'));
	}
};

export const assignLicenseToInputNodes = (ids: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/device/${licenseGroupId}/device/assign-license`, {
			serial: ids[0]
		});
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['input nodes'], () => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('inputNode:assign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('inputNode:assign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'inputNode:assign license:fail'));
	}
};

// export const addInputNodes = (ids: InputNode['id'][], successFn?: Function): AppThunk => async (
// 	dispatch,
// 	getState
// ) => {
// 	const state = getState();
// 	const licenseGroupId = getSelectedLicenseGroupId(state);
// 	const data = {
// 		add: ids
// 	};

// 	try {
// 		const response = await axios.patch(`/api/inputNode/${licenseGroupId}`, data);
// 		const err: any = findErrors(response);
// 		dispatch(
// 			licenseGroupsActions.getSelectedLicenseGroupData(() => {
// 				if (err.includes('already-associated')) {
// 					dispatch(
// 						appActions.alert(
// 							ids.length > 1 ? 'inputNodes:add:duplicate' : 'inputNode:add:duplicate',
// 							'warning'
// 						)
// 					);
// 				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
// 					dispatch(appActions.alert(ids.length > 1 ? 'inputNodes:add:fail' : 'inputNode:add:fail', 'error'));
// 				} else if (err.length === 0) {
// 					dispatch(
// 						appActions.alert(ids.length > 1 ? 'inputNodes:add:success' : 'inputNode:add:success', 'success')
// 					);
// 					if (successFn) successFn();
// 				}
// 			})
// 		);
// 	} catch (error) {
// 		dispatch(appActions.handleError(error, ids.length > 1 ? 'inputNodes:add:fail' : 'inputNode:add:fail'));
// 	}
// };

export const removeInputNodes = (ids: InputNode['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		delete: ids
	};
	try {
		const response = await axios.patch(`/api/device/${licenseGroupId}`, data);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['input nodes'], () => {
				if (err.includes('no-associated-inputNode')) {
					dispatch(
						appActions.alert(
							ids.length > 1 ? 'inputNodes:remove:not found' : 'inputNode:remove:not found',
							'warning'
						)
					);
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(
						appActions.alert(ids.length > 1 ? 'inputNodes:remove:fail' : 'inputNode:remove:fail', 'error')
					);
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(
							ids.length > 1 ? 'inputNodes:remove:success' : 'inputNode:remove:success',
							'success'
						)
					);
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, ids.length > 1 ? 'inputNodes:remove:fail' : 'inputNode:remove:fail'));
	}
};

export const importInputNodes = (nodeName: string): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const response = await axios.post(`/api/inputNode/${licenseGroupId}`, { name: nodeName });
		console.log(response);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['input nodes'], () => {
				if (err.includes('already-associated')) {
					dispatch(appActions.alert('inputNode:add:duplicate', 'warning'));
				} else if (err.includes('wrong-inputNode')) {
					dispatch(appActions.alert('inputNode:import:wrong-inputNode', 'warning'));
				} else if (err.includes('wrong-model')) {
					dispatch(appActions.alert('inputNode:import:wrong-model', 'warning'));
				} else if (err.includes('password-short')) {
					dispatch(appActions.alert('inputNode:import:password-short', 'warning'));
				} else if (err.includes('password-long')) {
					dispatch(appActions.alert('inputNode:import:password-long', 'warning'));
				} else if (err.includes('password-default')) {
					dispatch(appActions.alert('inputNode:import:password-default', 'warning'));
				} else if (err.includes('id-short')) {
					dispatch(appActions.alert('inputNode:import:id-short', 'warning'));
				} else if (err.includes('id-long')) {
					dispatch(appActions.alert('inputNode:import:id-long', 'warning'));
				} else if (err.includes('id-char')) {
					dispatch(appActions.alert('inputNode:import:id-char', 'warning'));
				} else if (err.includes('inputNode-name-long')) {
					dispatch(appActions.alert('inputNode:import:inputNode-name-long', 'warning'));
				} else if (err.includes('inputNode-location-long')) {
					dispatch(appActions.alert('inputNode:import:inputNode-location-long', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('inputNode:add:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('inputNode:add:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'inputNode:add:fail'));
	}
};
