/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getNotificationsById, getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Notification } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'notification-delete-failed',
	'no-associated-notification',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-notification-authorization',
	'failed-to-get-notifications',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-notification-group-failed',
	'notification-group-delete-failed'
];

export const addNotification = (data: {
	title: Notification['title'];
	message: Notification['message'];
	userGroupIds: Notification['userGroupIds'];
	startDate: Notification['startDate'];
	endDate: Notification['endDate'];
	color: Notification['color'];
	icon: Notification['icon'];
}): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const response = await axios.post('/api/notification', data);
		dispatch(appActions.alert('notification added', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'notifications']));
	} catch (error) {
		console.error(error);
		dispatch(appActions.alert('failed to add notification', 'error'));
	}
};

export const editNotification = (
	notificationId: Notification['id'],
	data: {
		title: Notification['title'];
		message: Notification['message'];
		userGroupIds: Notification['userGroupIds'];
		startDate: Notification['startDate'];
		endDate: Notification['endDate'];
		color: Notification['color'];
		icon: Notification['icon'];
	}
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		if (Object.values(data).filter(Boolean).length) {
			const response = await axios.patch(`/api/notification/${notificationId}`, data);
		}
		dispatch(appActions.alert('notification edited', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'notifications']));
	} catch (error) {
		console.error(error);
		dispatch(appActions.alert('failed to edit notification', 'error'));
	}
};

export const removeNotifications = (ids: Notification['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		delete: ids
	};
	try {
		const response = await axios.delete(`/api/notification?ids=${ids.join(',')}`);
		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['entitlements', 'notifications'], () => {
				if (err.includes('no-associated-notification')) {
					dispatch(
						appActions.alert(
							ids.length > 1 ? 'notification:remove:not found' : 'notification:remove:not found',
							'warning'
						)
					);
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(
						appActions.alert(
							ids.length > 1 ? 'notifications:remove:fail' : 'notification:remove:fail',
							'error'
						)
					);
				} else if (err.length === 0) {
					dispatch(
						appActions.alert(
							ids.length > 1 ? 'notifications:remove:success' : 'notification:remove:success',
							'success'
						)
					);
				}
			})
		);
	} catch (error) {
		dispatch(
			appActions.handleError(error, ids.length > 1 ? 'notifications:remove:fail' : 'notification:remove:fail')
		);
	}
};

// export const addNotificationsToNotificationGroup = (
// 	notificationIds: string[],
// 	notificationGroupId: string | undefined
// ): AppThunk => async (dispatch, getState) => {
// 	const notificationsById = getNotificationsById(getState());

// 	try {
// 		const data = notificationIds.map(notificationId => {
// 			return {
// 				id: notificationId,
// 				add: [notificationGroupId],
// 				delete: notificationsById[notificationId].notificationGroupId
// 			};
// 		});
// 		const response = await axios.patch(`/api/notification/notification-group`, { notifications: data });
// 		const err: any = findErrors(response);
// 		if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
// 			dispatch(
// 				appActions.alert(
// 					notificationIds.length > 1 ? 'notifications:move group:fail' : 'notification:move group:fail',
// 					'warning'
// 				)
// 			);
// 		} else {
// 			dispatch(
// 				appActions.alert(
// 					notificationIds.length > 1 ? 'notifications:move group:success' : 'notification:move group:success',
// 					'success'
// 				)
// 			);
// 		}
// 		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['notifications', 'notification groups']));
// 	} catch (error) {
// 		dispatch(
// 			appActions.handleError(
// 				error,
// 				notificationIds.length > 1 ? 'notifications:move group:fail' : 'notification:move group:fail'
// 			)
// 		);
// 	}
// };
