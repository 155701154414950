import * as Actions from 'app/store/actions';
import { PurgeStateAction } from './licenseGroups.types';

export const startPagesStarter = ['dashboard', 'workflows', 'devices', 'users', 'forms', 'logs'] as const;

export const startPages = [
	'home',
	'inbox',
	'jobs',
	'job-queue',
	'tracker',
	'dashboard',
	'apps',
	'workflows',
	'devices',
	'users',
	'forms',
	'logs',
	'roles'
] as const;

export const quickLinks = [
	'profile',
	'help',
	'inbox',
	'job-queue',
	'job-queue-management',
	'tracker',
	'jobs',
	'add-app',
	'create-workflow',
	'import-workflows',
	'devices',
	'import-devices',
	'add-device',
	'users',
	'invite-users',
	'forms',
	'create-form',
	'logs',
	'roles',
	'create-role'
] as const;

export const starterQuickLinks = [
	'profile',
	'help',
	'create-workflow',
	'import-workflows',
	'devices',
	'import-devices',
	'add-device',
	'users',
	'invite-users',
	'forms',
	'create-form',
	'logs',
	'roles',
	'create-role'
] as const;

export const bizQuickLinks = [
	'profile',
	'help',
	'inbox',
	'tracker',
	'jobs',
	'add-app',
	'create-workflow',
	'import-workflows',
	'devices',
	'import-devices',
	'add-device',
	'users',
	'invite-users',
	'forms',
	'create-form',
	'logs',
	'roles',
	'create-role'
] as const;

export const retentionOptions = [1, 7, 30, 90, 180, 365];

export type SignatureField = {
	signatureActive?: boolean;
	signatureType?: 'typeSign' | 'drawSign' | 'uploadImageSign';
	signatureImage?: string;
	signatureText?: string;
};

export const languages = ['en', 'ja', 'fr', 'es', 'de', 'it'] as const;

export type UserPreferences = {
	startPage: typeof startPages[number];
	homePageLayout: 'rows' | 'columns' | 'grid' | 'left';
	quickLinks: [
		typeof quickLinks[number] | undefined,
		typeof quickLinks[number] | undefined,
		typeof quickLinks[number] | undefined
	];
	appearance: 'dark' | 'light' | 'system';
	// background image URL
	siteBackground: string;
	tableHeaderBackground: 'standard' | 'color';
	tableHeaderStandardBackgroundOpacity: number;
	tableHeaderColorBackgroundOpacity: number;
	pageHeaderBackgroundOpacity: number;
	navbarBackgroundOpacity: number;
	messageRetentionDaysInfo: number;
	messageRetentionDaysError: number;
	messageRetentionDaysJob: number;
	messageRetentionDaysOther: number;
	language: typeof languages[number];
};

export type Profile = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	mpData: {
		firstName: string;
		lastName: string;
		email: string;
	};
	mpId?: string;
	country: string;
	awsRegion: 'us-east-1' | 'ca-central-1' | 'eu-central-1' | 'ap-northeast-1';
	tenantId?: string;
	tokens: {
		accessToken: string;
		idToken: string;
		expirationDate: number;
	};
	preferences: UserPreferences;
	appData: {
		lastAcknowledgedNotificationDate: number | undefined;
		acknowledgedIds: string[];
	};
	signature?: SignatureField;
};

export type ProfileState = {
	info: Profile;
	previousProfileId: string | undefined;
};

type GetProfileSuccessAction = {
	type: typeof Actions.GET_PROFILE_SUCCESS;
	payload: {
		data: {
			user: Profile;
		};
	};
};

type GetSignatureSuccessAction = {
	type: typeof Actions.GET_SIGNATURE_SUCCESS;
	payload: {
		data: {
			signature: SignatureField;
		};
	};
}

export type ProfileActionTypes = GetProfileSuccessAction | GetSignatureSuccessAction | PurgeStateAction;
