import FuseUtils from '@fuse/utils';
import { licenseGroupDomain } from 'app/hooks/useGetPageSection';
import Root from 'app/main/root/RootPage';
import AboutPageConfig from 'app/main/about/AboutPageConfig';
import AccountPageConfig from 'app/main/account/AccountPageConfig';
import SuccessPageConfig from 'app/main/account/SuccessPageConfig';
import ComingSoonPageConfig from 'app/main/coming-soon/ComingSoonPageConfig';
import ConfirmManagerErrorPageConfig from 'app/main/confirm-manager/ConfirmManagerErrorPageConfig';
import ConfirmationPageConfig from 'app/main/confirmation/ConfirmationPageConfig';
import { AdminContactUsPageConfig, PublicContactUsPageConfig } from 'app/main/contact-us/ContactUsPageConfig';
import DashboardPageConfig from 'app/main/dashboard/DashboardPageConfig';
import DevicesPageConfig from 'app/main/devices/DevicesPageConfig';
import ErrorPageConfig from 'app/main/error/ErrorPageConfig';
import Error500PageConfig from 'app/main/error500/Error500PageConfig';
import FeaturesPageConfig from 'app/main/features/FeaturesPageConfig';
import FormsPageConfig from 'app/main/forms/FormsPageConfig';
import AppsPageConfig from 'app/main/apps/AppsPageConfig';
import HomePageConfig from 'app/main/home/HomePageConfig';
import InboxPageConfig from 'app/main/home/inbox/InboxPageConfig';
import JobQueuePageConfig from 'app/main/home/jobqueue/JobQueuePageConfig';
import JobQueueManagementPageConfig from 'app/main/home/jobqueue/job-queue-management/JobQueueManagementPageConfig';
import JobsPageConfig from 'app/main/home/jobs/JobsPageConfig';
import JobPageConfig from 'app/main/home/job/JobPageConfig';
import TrackerPageConfig from 'app/main/home/tracker/TrackerPageConfig';
import InvitePageConfig from 'app/main/invite/InvitePageConfig';
import LegalPageConfig from 'app/main/legal/LegalPageConfig';
import LogsPageConfig from 'app/main/logs/LogsPageConfig';
import MaintenancePageConfig from 'app/main/maintenance/MaintenancePageConfig';
import ManagedLicenseGroupsPageConfig from 'app/main/managed-license-groups/ManagedLicenseGroupsPageConfig';
import ManagerDashboardPageConfig from 'app/main/manager-dashboard/ManagerDashboardPageConfig';
import OnboardingPageConfig from 'app/main/onboarding/OnboardingPageConfig';
import AdminProfilePageConfig from 'app/main/profile/AdminProfilePageConfig';
import PublicProfilePageConfig from 'app/main/profile/PublicProfilePageConfig';
import ReceiptPageConfig from 'app/main/receipt/ReceiptPageConfig';
import RolesPageConfig from 'app/main/roles/RolesPageConfig';
import NotificationsPageConfig from 'app/main/notifications/NotificationsPageConfig';
import SettingsPageConfig from 'app/main/settings/SettingsPageConfig';
import SubscriptionDetailsPageConfig from 'app/main/subscription-details/SubscriptionDetailsPageConfig';
import TenantReportsPageConfig from 'app/main/tenant-reports/TenantReportsPageConfig';
import UsersPageConfig from 'app/main/users/UsersPageConfig';
import WelcomePageConfig from 'app/main/landing/WelcomePageConfig';
import AboutScanTripCloudConfig from 'app/main/landing/AboutScanTripCloudPageConfig';
import AboutStratusConfig from 'app/main/landing/AboutStratusPageConfig';
import WorkflowsPageConfig from 'app/main/workflows/WorkflowsPageConfig';
import React from 'react';
import InputPageConfig from 'app/main/input/InputPageConfig';
import NamingPageConfig from 'app/main/naming/NamingPageConfig';

const routeConfigs = [
	// COMMON
	MaintenancePageConfig,
	ComingSoonPageConfig,
	ConfirmationPageConfig,
	ErrorPageConfig,
	ReceiptPageConfig,
	LegalPageConfig,
	Error500PageConfig,
	AboutPageConfig,
	ConfirmManagerErrorPageConfig,
	// only render either Admin or Public/Manager/Onboarding pages based on domain
	...(licenseGroupDomain
		? [
				// ADMIN
				WorkflowsPageConfig,
				DashboardPageConfig,
				UsersPageConfig,
				DevicesPageConfig,
				FormsPageConfig,
				LogsPageConfig,
				RolesPageConfig,
				NotificationsPageConfig,
				SettingsPageConfig,
				SubscriptionDetailsPageConfig,
				AdminProfilePageConfig,
				InvitePageConfig,
				AccountPageConfig,
				SuccessPageConfig,
				AdminContactUsPageConfig,
				AppsPageConfig,
				HomePageConfig,
				InboxPageConfig,
				JobQueuePageConfig,
				JobQueueManagementPageConfig,
				TrackerPageConfig,
				JobsPageConfig,
				JobPageConfig,
				InputPageConfig,
				NamingPageConfig
				// ReportsPageConfig,
		  ]
		: [
				// PUBLIC
				WelcomePageConfig,
				AboutScanTripCloudConfig,
				AboutStratusConfig,
				FeaturesPageConfig,
				PublicProfilePageConfig,
				// MANAGER
				ManagerDashboardPageConfig,
				ManagedLicenseGroupsPageConfig,
				TenantReportsPageConfig,
				// ONBOARDING
				OnboardingPageConfig,
				PublicContactUsPageConfig
		  ])
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		exact: true,
		component: () => <Root />
	},
	{
		path: '/admin',
		exact: true,
		component: () => <Root />
	},
	{
		component: React.lazy(() => import('app/main/error404/Error404Page'))
	}
];

export default routes;
