import axios from 'app/client';
import { AppThunk } from 'app/store';
import { getSelectedLicenseGroupId, getProfile } from 'app/store/reducers';
import { MpWorkflow } from 'app/store/types';
import * as appActions from './app.actions';

const massageRawMpWorkflows = (rawMpWorkflow: any) => {
	const mpWorkflow: MpWorkflow = {
		id: rawMpWorkflow.id,
		serial: rawMpWorkflow.serial,
		name: rawMpWorkflow.name,
		friendlyName: rawMpWorkflow.friendlyName || undefined,
		family: rawMpWorkflow.family,
		generation: rawMpWorkflow.generation,
		productLine: rawMpWorkflow.productLine,
		licenseGroupId: rawMpWorkflow.tenantId,
		publicKey: rawMpWorkflow.publicKey
	};

	return mpWorkflow;
};

export const GET_MP_WORKFLOWS_SUCCESS = 'GET_MP_WORKFLOWS_SUCCESS';

export const getMpWorkflows = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const { data: rawMpWorkflows } = await axios.get(`/api/v1/user/${licenseGroupId}/${profile.id}/workflows`);

		const mpWorkflows: MpWorkflow[] = rawMpWorkflows.map(massageRawMpWorkflows);

		const validMpWorkflows = mpWorkflows.filter(({ generation }) => ['IT5', 'IT6'].includes(generation));

		dispatch({
			type: GET_MP_WORKFLOWS_SUCCESS,
			payload: {
				list: validMpWorkflows
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
