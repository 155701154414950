import FuseScrollbars from '@fuse/core/FuseScrollbars';
import clsx from 'clsx';
import React from 'react';

function FusePageSimpleSidebarContent(props) {
	const { classes } = props;

	return (
		<FuseScrollbars enable={props.innerScroll}>
			{props.header && (
				<div
					className={clsx(
						classes.sidebarHeader,
						props.variant,
						props.sidebarInner && classes.sidebarHeaderInnerSidebar
					)}
				>
					{props.header}
				</div>
			)}

			{props.content && <div className={classes.sidebarContent}>{props.content}</div>}
		</FuseScrollbars>
	);
}

export default FusePageSimpleSidebarContent;
