import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from 'app/components/Icons';

const useStyles = makeStyles({
	root: {
		position: 'relative',
		padding: '4px 23px'
	},
	rootWithClose: {
		paddingRight: 48
	},
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		paddingRight: 6
	}
});

type Props = DialogTitleProps & {
	onClose?: () => void;
};

const DialogTitle = (props: Props) => {
	const classes = useStyles();

	return (
		<MuiDialogTitle
			classes={{
				root: clsx({ [classes.rootWithClose]: !!props.onClose }, classes.root)
			}}
		>
			{props.children}
			{props.onClose && (
				<div className={classes.closeButtonWrapper}>
					<IconButton className="py-5" onClick={props.onClose} color="inherit">
						<CloseIcon />
					</IconButton>
				</div>
			)}
		</MuiDialogTitle>
	);
};

export default DialogTitle;
