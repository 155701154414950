import MomentUtils from '@date-io/moment';
// import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { scrollToFragment } from 'scroll-to-fragment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import { Provider } from 'app/modules/react-redux';
import { Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import AppContext from './AppContext';
// import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store, { persistor } from './store';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

scrollToFragment({ history });

const queryClient = new QueryClient();

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					{/* I18nextProvider *shouldn't* be needed with React.Suspense is use but there seems to be some bug */}
					<I18nextProvider i18n={i18n}>
						<PersistGate persistor={persistor} loading={null}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								{/* <Auth> */}
								<Router history={history}>
									<QueryParamProvider ReactRouterRoute={Route}>
										<QueryClientProvider client={queryClient}>
											{/* <FuseAuthorization> */}
											<FuseTheme>
												<FuseLayout />
											</FuseTheme>
											{/* </FuseAuthorization> */}
										</QueryClientProvider>
									</QueryParamProvider>
								</Router>
								{/* </Auth> */}
							</MuiPickersUtilsProvider>
						</PersistGate>
					</I18nextProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
