import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { AlertSubscriptionsState, AlertSubscriptionsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: AlertSubscriptionsState = {};

const alertSubscriptionsReducer = (state = initialState, action: AlertSubscriptionsActionTypes) => {
	switch (action.type) {
		case Actions.GET_ALERT_SUBSCRIPTIONS_SUCCESS: {
			const { licenseGroupId, data: alertSubscriptions } = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: alertSubscriptions
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'alertSubscriptions',
		storage: localForage
	},
	alertSubscriptionsReducer
);

// Selectors
export const getAlertSubscriptions = ({ app: { selectedLicenseGroupId }, alertSubscriptions }: RootState) =>
	alertSubscriptions[selectedLicenseGroupId]
		? Object.values(alertSubscriptions[selectedLicenseGroupId]!.byId)
		: undefined;
