import React from 'react';

const UsersPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/users',
			exact: true,
			component: React.lazy(() => import('./UsersPage'))
		}
	]
};

export default UsersPageConfig;
