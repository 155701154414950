import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AppsState, AppsActionTypes } from 'app/store/types';
import _ from '@lodash';
import * as Actions from '../actions';
import { getRolePermissionCheck } from './entitlements.reducer';

const initialState: AppsState = {};

const appsReducer = (state = initialState, action: AppsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { apps }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(apps && { byId: apps })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'apps',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	appsReducer
);

// Selectors
// export const getAppsById = ({ app: { selectedLicenseGroupId }, apps }: RootState) => apps[selectedLicenseGroupId].byId;
export const getAppsById = (state: RootState) => {
	const {
		app: { selectedLicenseGroupId },
		apps
	} = state;
	const rolePermissionCheck = getRolePermissionCheck(state);
	const allAppsById = apps[selectedLicenseGroupId].byId;
	const filteredApps = _.pickBy(
		allAppsById,
		({ type }) =>
			(type === 'internal' && rolePermissionCheck('appsTab.internalSection.view')) ||
			(type === 'external' && rolePermissionCheck('appsTab.externalSection.view')) ||
			(type === 'url' && rolePermissionCheck('appsTab.urlSection.view'))
	);
	return filteredApps;
};

export const getApps = createSelector([getAppsById], appsById => Object.values(appsById));
