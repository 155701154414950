// DEV NOTE::this logic currently needs to be copy-paste repeated in `index.html` (sans TypeScript)
export const getPlanDomain = (
	plan: 'stc' | 'stratus' | 'stratus-starter' | 'stratus-business' | 'stratus-enterprise'
) =>
	({
		stc: process.env.REACT_APP_STC_DOMAIN,
		stratus: process.env.REACT_APP_STRATUS_DOMAIN,
		'stratus-starter': process.env.REACT_APP_STRATUS_DOMAIN,
		'stratus-business': process.env.REACT_APP_STRATUS_DOMAIN,
		'stratus-enterprise': process.env.REACT_APP_STRATUS_DOMAIN
	}[plan]);

export const licenseGroupPlanDesignCheck = (plan: 'stc' | 'stratus') => {
	const planDomain = getPlanDomain(plan);
	return window.location.host.endsWith(planDomain);
};
