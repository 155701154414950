import React from 'react';

const AppsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/apps',
			exact: true,
			component: React.lazy(() => import('./AppsPage'))
		}
	]
};

export default AppsPageConfig;
