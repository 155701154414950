import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { NotificationGroup } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_NOTIFICATION_GROUP_ORDER_SUCCESS = 'GET_NOTIFICATION_GROUP_ORDER_SUCCESS';
export const UPDATE_NOTIFICATION_GROUP_ORDER_SUCCESS = 'UPDATE_NOTIFICATION_GROUP_ORDER_SUCCESS';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'no-associated-notification',
	'partial-failure',
	'unexpected-error',
	'failed-to-get-notification-authorization',
	'failed-to-get-notifications',
	'unauthorized',
	'already-associated',
	'not-found',
	'update-notification-group-failed',
	'notification-group-delete-failed'
];

export const createNotificationGroup = ({ name }: { name: string }): AppThunk => async (dispatch, getState) => {
	const data = {
		name
	};

	try {
		const response = await axios.post(`/api/notification/notification-group`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['notification groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('notification group name already exists')) {
						// change color
						dispatch(appActions.alert('notification group:create:fail', 'warning'));
						// change error code
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('notification group:create:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('notification group:create:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'notification group:create:fail'));
	}
};

export const editNotificationGroup = ({ id, name }: { id: NotificationGroup['id']; name: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const data = {
		id,
		name
	};

	try {
		const response = await axios.post(`/api/notification/notification-group`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['notification groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('notification group name already exists')) {
						// change color
						dispatch(appActions.alert('notification group:name:fail', 'warning'));
						// change error code
					} else if (err.contains('notification group no longer exists')) {
						dispatch(appActions.alert('notification group:name:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('notification group:name:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('notification group:name:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'notification group:name:fail'));
	}
};

export const deleteNotificationGroup = (notificationGroupId: NotificationGroup['id']): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		const response = await axios.delete(`/api/notification/notification-group/${notificationGroupId}`);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['notifications', 'notification groups'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('notification group no longer exists')) {
						// change color
						dispatch(appActions.alert('notification group:name:fail', 'warning'));
						// change error code
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('notification group:delete:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('notification group:delete:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'notification group:delete:fail'));
	}
};

// DEV NOTE::we just order on the backend beforehand now - this should be done for all pages
// export const getNotificationGroupOrder = (): AppThunk => async (dispatch, getState) => {
// 	const licenseGroupId = getSelectedLicenseGroupId(getState());

// 	try {
// 		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/notification`);

// 		dispatch({
// 			type: GET_NOTIFICATION_GROUP_ORDER_SUCCESS,
// 			payload: {
// 				order: groupOrder,
// 				licenseGroupId
// 			}
// 		});
// 	} catch (error) {
// 		// TODO: update error handling
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const updateNotificationGroupOrder = (groupIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'notification',
		order: groupIds
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};
