import React from 'react';

const JobsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/(home|home/inbox|home/job-queue|home/tracker|home/jobs)/job/:jobId',
			exact: true,
			component: React.lazy(() => import('./JobPage'))
		}
	]
};

export default JobsPageConfig;
