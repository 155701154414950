import axios from 'app/client';
import FuseLoading from '@fuse/core/FuseLoading';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import Paper from '@material-ui/core/Paper';
import Markdown from 'app/components/Markdown';
import usePageTitle from 'app/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';
import MessageView from 'app/components/MessageView';
import { getRegion } from 'app/store/reducers';
import * as Actions from 'app/store/actions';
import FusePageSimple from '@fuse/core/FusePageSimple';

type LegalDocType = 'eula' | 'terms' | 'privacy';

type LegalDoc = {
	data: {
		documentId: LegalDocType;
		region: string;
		language: string;
		version: number;
		body: string;
	};
};

type Status = 'loading' | 'success' | 'error';

const LegalPage = ({ type }: { type: LegalDocType }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	usePageTitle(`legal:${type}`);

	const region = useSelector(getRegion);

	const [status, setStatus] = useState<Status>('loading');
	const [doc, setDoc] = useState<string>();

	useEffect(() => {
		const getDoc = async () => {
			try {
				const {
					data: { body }
				} = await axios.get<any, LegalDoc>(
					`https://api.konicaminoltamarketplace.com/legal-document/v1/document/${type}/${region}/latest`,
					{
						// don't pass Authorization header (causes the API to error ATM)
						transformRequest: (requestData, headers) => {
							// @ts-ignore
							delete headers?.common.Authorization;
							return requestData;
						}
					}
				);
				setDoc(body);
				setStatus('success');
			} catch (error) {
				dispatch(Actions.handleError(error));
				setStatus('error');
			}
		};

		getDoc();
	}, [dispatch, region, type]);

	if (status === 'loading') {
		return <FuseLoading />;
	}

	if (status === 'error') {
		return (
			<MessageView
				icon="error"
				title={t('confirmation:error:title')}
				message={[t('confirmation:error:message')]}
			/>
		);
	}

	return (
		<FusePageSimple
			content={
				<Paper className="mx-48 my-36 p-32 enterprise:shadow-0">
					<h1 className="pb-36">{t(`legal:${type}:title`)}</h1>
					<Markdown source={doc} />
				</Paper>
			}
		/>
	);
};

export default LegalPage;
