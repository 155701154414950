import clsx from 'clsx';
import React from 'react';

const LogoIcon = ({ className, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
	return (
		<>
			<img
				src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-stc.svg`}
				alt=""
				className={clsx(className, 'enterprise:hidden')}
				{...props}
			/>
			<img
				src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-stratus.svg`}
				alt=""
				className={clsx(className, 'starter:hidden')}
				{...props}
			/>
		</>
	);
};

export default LogoIcon;
