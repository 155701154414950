import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { RolesState, RolesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: RolesState = {};

const rolesReducer = (state = initialState, action: RolesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { roles }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(roles && { byId: roles })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'roles',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	rolesReducer
);

// Selectors
export const getRolesById = ({ app: { selectedLicenseGroupId }, roles }: RootState) =>
	roles[selectedLicenseGroupId].byId;

export const getRoles = createSelector([getRolesById], rolesById => Object.values(rolesById));
