import React from 'react';

const AboutScanTripCloudPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/about-dispatcher-scantrip-cloud',
			exact: true,
			component: React.lazy(() => import('./AboutScanTripCloudPage'))
		}
	]
};

export default AboutScanTripCloudPageConfig;
