import localForage from 'localforage';
import i18n from 'i18n';
import _ from '@lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import themesConfig from 'app/fuse-configs/themesConfig';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { ProfileState, ProfileActionTypes, UserPreferences, languages, SignatureField, Profile } from 'app/store/types';
import { getSelectedLicenseGroupId, getSiteBackgroundCache } from 'app/store/reducers/app.reducer';
import { createSelector } from '@reduxjs/toolkit';
import { getSiteBackgroundImageWithFallbacks } from 'app/utils/helpers';
import { licenseGroupPlanDesignCheck } from 'app/utils/tenant-plan';
import * as Actions from '../actions';

const initialState: ProfileState = {
	info: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	previousProfileId: undefined
};

const profileReducer = (state = initialState, action: ProfileActionTypes) => {
	switch (action.type) {
		case Actions.GET_PROFILE_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				info: data?.user,
				previousProfileId: data?.user?.id ?? undefined
			};
		}
		case Actions.GET_SIGNATURE_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				info: {
					...state.info,
					signature: { ...data.signature }
				}
			};
		}
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'profile',
		storage: localForage,
		whitelist: ['previousProfileId'] // wipe user info on refresh
	},
	profileReducer
);

const currentLanguage = (languages.includes(i18n.language.slice(0, 2) as any)
	? i18n.language.slice(0, 2)
	: 'en') as UserPreferences['language'];

export const defaultUserPreferencesStarter: UserPreferences = {
	startPage: 'workflows',
	homePageLayout: 'rows',
	quickLinks: [undefined, undefined, undefined],
	appearance: 'light',
	siteBackground: 'colors/white.svg',
	tableHeaderBackground: 'standard',
	tableHeaderStandardBackgroundOpacity: 100,
	tableHeaderColorBackgroundOpacity: 100,
	pageHeaderBackgroundOpacity: 100,
	navbarBackgroundOpacity: 100,
	messageRetentionDaysInfo: 7,
	messageRetentionDaysError: 7,
	messageRetentionDaysJob: 7,
	messageRetentionDaysOther: 7,
	language: currentLanguage
};
export const defaultUserPreferencesEnterprise: UserPreferences = {
	startPage: 'home',
	homePageLayout: 'rows',
	quickLinks: [undefined, undefined, undefined],
	appearance: 'light',
	siteBackground: 'tropical-image.jpg',
	tableHeaderBackground: 'color',
	tableHeaderStandardBackgroundOpacity: 80,
	tableHeaderColorBackgroundOpacity: 80,
	pageHeaderBackgroundOpacity: 50,
	navbarBackgroundOpacity: 50,
	messageRetentionDaysInfo: 7,
	messageRetentionDaysError: 7,
	messageRetentionDaysJob: 7,
	messageRetentionDaysOther: 7,
	language: currentLanguage
};

const getDefaultSignatureProfile = (profileInfo: Profile):SignatureField => {
	return {
		signatureActive: false,
		signatureImage: '',
		signatureType: 'typeSign',
		signatureText: `${profileInfo.firstName} ${profileInfo.lastName}`
	};
};

const getUserPreferencesValuesOrDefaults = (preferences: UserPreferences) => {
	return licenseGroupPlanDesignCheck('stratus')
		? !_.isEmpty(preferences)
			? preferences
			: defaultUserPreferencesEnterprise
		: defaultUserPreferencesStarter;
};

const getUserSignatureValuesOrDefaults = (profile: Profile, signature?: SignatureField, ) => {
	return !_.isEmpty(signature) ? signature : getDefaultSignatureProfile(profile);
};

export const calculatedUserPreferences = ({
	licenseGroupId,
	profileId,
	siteBackgroundCache
}: {
	licenseGroupId: string;
	profileId: string;
	siteBackgroundCache: number;
}) => (preferences: UserPreferences) => {
	const appearance =
		preferences.appearance === 'system'
			? window.matchMedia('(prefers-color-scheme: dark)').matches
				? 'dark'
				: 'light'
			: preferences.appearance;

	const lightBackgroundColor = themesConfig.default.palette.background.paper;
	const darkBackgroundColor = themesConfig.defaultDark.palette.background.paper;
	const backgroundColor = appearance === 'dark' ? darkBackgroundColor : lightBackgroundColor;

	const siteBackgroundImageWithFallbacks = getSiteBackgroundImageWithFallbacks({
		licenseGroupId,
		profileId,
		siteBackgroundCache,
		appearance
	});

	return {
		startPage: preferences.startPage,
		homePageLayout: preferences.homePageLayout,
		quickLinks: preferences.quickLinks.filter(
			(quickLink): quickLink is NonNullable<UserPreferences['quickLinks'][number]> => !!quickLink
		),
		appearance,
		siteBackground:
			preferences.siteBackground === '%CUSTOM%'
				? siteBackgroundImageWithFallbacks
						.map(siteBackgroundImageUrl => `url("${siteBackgroundImageUrl}")`)
						.join(', ')
				: `url("${process.env.PUBLIC_URL}/assets/images/featured-backgrounds/${
						preferences.siteBackground
				  }"), url(${siteBackgroundImageWithFallbacks[siteBackgroundImageWithFallbacks.length - 1]})`,
		tableHeaderBackground: preferences.tableHeaderBackground,
		tableHeaderStandardBackgroundColor: fade(
			backgroundColor,
			preferences.tableHeaderStandardBackgroundOpacity / 100
		),
		tableHeaderColorBackgroundColor: {
			purple: fade('#79679F', preferences.tableHeaderColorBackgroundOpacity / 100),
			green: fade('#8DC567', preferences.tableHeaderColorBackgroundOpacity / 100),
			yellow: fade('#FFE787', preferences.tableHeaderColorBackgroundOpacity / 100),
			blue: fade('#A1C8FB', preferences.tableHeaderColorBackgroundOpacity / 100)
		},
		pageHeaderBackgroundColor: fade(backgroundColor, preferences.pageHeaderBackgroundOpacity / 100),
		navbarBackgroundColor: fade(backgroundColor, preferences.navbarBackgroundOpacity / 100),
		language: preferences.language
	};
};

export type TableHeaderColorBackgroundColorKey = keyof (ReturnType<typeof calculatedUserPreferences> extends (
	preferences: any
) => infer R
	? R extends { tableHeaderColorBackgroundColor: infer T }
		? T
		: never
	: never);

export const getProfile = ({ profile }: RootState) => profile.info;
export const getUserPreferencesValues = ({ profile }: RootState) =>
	getUserPreferencesValuesOrDefaults(profile.info?.preferences);
export const getUserSignatureValues = ({ profile }: RootState) =>
	getUserSignatureValuesOrDefaults(profile.info, profile.info?.signature);
export const getUserPreferences = createSelector(
	[getUserPreferencesValues, getSelectedLicenseGroupId, getProfile, getSiteBackgroundCache],
	(preferencesValues, selectedLicenseGroupId, profile, siteBackgroundCache) => {
		return calculatedUserPreferences({
			licenseGroupId: selectedLicenseGroupId,
			profileId: profile?.id,
			siteBackgroundCache
		})(preferencesValues);
	}
);

export const getPreviousProfileId = ({ profile }: RootState) => profile.previousProfileId;
