import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';

const useStyles = makeStyles(() => ({
	root: {
		width: 280
	}
}));

function QuickPanel(_props) {
	const dispatch = useDispatch();
	const state = useSelector(({ fuse }) => fuse.quickPanel.state);

	const classes = useStyles();

	return (
		<Drawer
			classes={{ paper: classes.root }}
			open={state}
			anchor="right"
			onClose={_ev => dispatch(Actions.toggleQuickPanel())}
		>
			<FuseScrollbars>
				<Typography>Quick Panel</Typography>
			</FuseScrollbars>
		</Drawer>
	);
}

export default React.memo(QuickPanel);
