import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import useGetPageSection from 'app/hooks/useGetPageSection';
import { StarterLogo, EnterpriseLogo } from 'app/components/Icons';
import { licenseGroupPlanDesignCheck } from 'app/utils/tenant-plan';

const useStyles = makeStyles(theme => ({
	logo: {
		color: theme.palette.text.primary,
		marginLeft: '0.5rem',
		marginRight: '1.5rem',
		height: 43,
		width: 'auto',
		maxWidth: 'unset'
	}
}));

type Props = {
	link?: boolean;
	classes?: {
		root?: string;
		logo?: string;
	};
};
const Logo = ({ link = false, classes: classesProp = {} }: Props) => {
	const classes = useStyles();

	const pageSection = useGetPageSection();

	const linkComponent = (
		<div className={clsx(classesProp.root, 'flex items-center')}>
			<div>
				{licenseGroupPlanDesignCheck('stratus') ? (
					<EnterpriseLogo className={clsx(classes.logo, classesProp.logo)} />
				) : (
					<StarterLogo className={clsx(classes.logo, classesProp.logo)} />
				)}
			</div>
		</div>
	);

	return link ? (
		<Link to={pageSection.licenseGroupDomain ? '/' : pageSection.manager ? '/manager/tenants' : '/welcome'}>
			{linkComponent}
		</Link>
	) : (
		linkComponent
	);
};

export default Logo;
