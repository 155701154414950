import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { InputNodesState, InputNodesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: InputNodesState = {};

const inputNodesReducer = (state = initialState, action: InputNodesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { inputNodes }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(inputNodes && { byId: inputNodes })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'inputNodes',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	inputNodesReducer
);

// Selectors
export const getInputNodesById = ({ app: { selectedLicenseGroupId }, inputNodes }: RootState) =>
	inputNodes[selectedLicenseGroupId].byId;

export const getInputNodes = createSelector([getInputNodesById], inputNodesById => Object.values(inputNodesById));
