import { amber, blue, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {},
	success: {
		backgroundColor: green[600],
		color: '#FFFFFF'
	},
	error: {
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.getContrastText(theme.palette.error.dark)
	},
	info: {
		backgroundColor: blue[600],
		color: '#FFFFFF'
	},
	warning: {
		backgroundColor: amber[600],
		color: '#FFFFFF',
		alignItems: 'flex-start'
	},
	action: {
		marginTop: 2
	}
}));

const variantIcon = {
	success: '',
	warning: <WarningIcon color="inherit" />,
	error: <ErrorIcon color="inherit" />,
	info: <InfoIcon color="inherit" />
};

function FuseMessage(_props) {
	const { t } = useTranslation('alert');
	const dispatch = useDispatch();
	const state = useSelector(({ fuse }) => fuse.message.state);
	const options = useSelector(({ fuse }) => fuse.message.options);

	const classes = useStyles();

	const message =
		['error', 'warning'].includes(options.variant) && typeof options.message === 'string'
			? options.message ?? 'something went wrong'
			: options.message;

	return (
		<Snackbar
			{...options}
			open={state}
			onClose={() => dispatch(Actions.hideMessage())}
			classes={{
				root: classes.root
			}}
		>
			<SnackbarContent
				className={clsx(classes[options.variant])}
				message={
					<div className="flex items-start">
						{variantIcon[options.variant] && variantIcon[options.variant]}
						<Typography className="mx-8">{typeof message === 'string' ? t(message) : message}</Typography>
					</div>
				}
				action={
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						className={classes.action}
						size="small"
						onClick={() => dispatch(Actions.hideMessage())}
					>
						<CloseIcon />
						{/* <Icon>close</Icon> */}
					</IconButton>
				}
			/>
		</Snackbar>
	);
}

export default React.memo(FuseMessage);
