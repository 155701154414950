/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Role } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = ['role-delete-failed', 'unexpected-error'];

export const createRole = (name: string, permissions: Role['permissions']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		permissions
	};

	try {
		const response = await axios.post(`/api/role/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['roles'], () => {
				if (err.length > 0) {
					// change error code
					if (err.contains('Role name already exists')) {
						// change color
						dispatch(appActions.alert('role:create:name already exists', 'warning'));
						// change error code
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('role:create:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('role:create:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'role:create:fail'));
	}
};

export const editRole = (id: string, name: string, permissions: Role['permissions']): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		id,
		name,
		permissions
	};

	try {
		const response = await axios.post(`/api/role/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['roles'], () => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('role:edit:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('role:edit:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'role:edit:fail'));
	}
};

export const deleteRole = (id: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		id
	};

	try {
		const response = await axios.delete(`/api/role/${licenseGroupId}/${id}`, { data });

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['roles'], () => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('role:delete:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('role:delete:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'role:delete:fail'));
	}
};
