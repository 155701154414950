import { Badge, Box, IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { NotificationsIcon } from 'app/components/Icons';
import * as Actions from 'app/store/actions';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { getProfile, getUserInboxSelector } from 'app/store/reducers';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxItem } from 'app/store/types';
import { useLocation } from 'react-router';

const useStyles = makeStyles(theme => ({
	notificationPopup: {
		color: theme.palette.common.white,
		backgroundColor: '#0870F8',
		width: 'calc(100vw - 280px - 20px)',
		padding: '2.8rem 1.2rem 2.8rem 9.6rem',
		'& button.MuiIconButton-root': {
			height: 32,
			width: 32,
			marginRight: 16
		}
	},
	errorNotificationPopup: {
		color: theme.palette.common.white,
		backgroundColor: red[500]
	},
	notificationIcon: {
		fontSize: '4.8rem',
		position: 'absolute',
		top: '50%',
		left: '4.8rem',
		transform: 'translate(-50%, -50%)'
	},
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '0.2rem',
		marginTop: 12
	}
}));

const Notification = () => {
	const { t } = useTranslation();
	const { t: tI } = useTranslation('inbox');
	const userInboxById = useSelector(getUserInboxSelector);
	const dispatch = useDispatch();
	const classes = useStyles();

	const [activeNotificationIndex, setActiveNotificationIndex] = React.useState(0);

	const {
		appData: { lastAcknowledgedNotificationDate }
	} = useSelector(getProfile);

	const saveLastAcknowledgedNotificationDate = (timestamp: InboxItem['createdAt']) => {
		dispatch(Actions.editUserAppData({ lastAcknowledgedNotificationDate: timestamp }));
	};

	const validInboxNotifications = Object.values(userInboxById ?? {})
		?.sort((a, b) => b.createdAt - a.createdAt)
		?.filter(({ type }) => type === 'error');
	const lastAcknowledgedNotificationIndex = (() => {
		if (lastAcknowledgedNotificationDate) {
			const index = validInboxNotifications?.findIndex(
				({ createdAt }) => createdAt <= lastAcknowledgedNotificationDate
			);
			return index === -1 ? Infinity : index;
		}
		return Infinity;
	})();

	const unacknowledgedNotifications = validInboxNotifications?.slice(0, lastAcknowledgedNotificationIndex);

	const { pathname } = useLocation();

	useEffect(() => {
		dispatch(Actions.getInbox());
	}, [dispatch, pathname]);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		saveLastAcknowledgedNotificationDate(unacknowledgedNotifications[0].createdAt);
	};

	const open = !!anchorEl;

	if (unacknowledgedNotifications.length === 0) {
		return (
			<Tooltip title={t('notification:no new notifications')}>
				<span>
					<IconButton className="w-64 h-64 starter:text-white landing-toolbar-text-white" disabled>
						<NotificationsIcon style={{ maxWidth: 24 }} />
					</IconButton>
				</span>
			</Tooltip>
		);
	}

	const activeNotification = unacknowledgedNotifications[activeNotificationIndex];

	return (
		<>
			<IconButton className="w-64 h-64 starter:text-white landing-toolbar-text-white" onClick={handleClick}>
				<Badge color="error" badgeContent={unacknowledgedNotifications.length}>
					<NotificationsIcon style={{ maxWidth: 24 }} />
				</Badge>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<div
					className={clsx(classes.notificationPopup, {
						[classes.errorNotificationPopup]: activeNotification.type === 'error'
					})}
				>
					{activeNotification.type === 'error' ? (
						<ErrorIcon className={classes.notificationIcon} />
					) : (
						<InfoIcon className={classes.notificationIcon} />
					)}
					<div className="mb-8 flex items-center">
						<Typography className="pr-16" variant="h6">
							{/* {t('workflow:error')} */}
							{/* {t(`inbox:header:${activeNotification.type ?? 'info'}`)} */}
							{activeNotification.type === 'error'
								? activeNotification.origin.type === 'workflow'
									? t('notification:header:workflow error')
									: t('notification:header:error')
								: t('notification:header:info')}
						</Typography>
						<div
							className={clsx(
								{
									'opacity-0 pointer-events-none': unacknowledgedNotifications.length <= 1
								},
								'flex items-center'
							)}
						>
							<Typography className="pr-16" variant="h6">
								<span className="font-normal">
									{activeNotificationIndex + 1}/{unacknowledgedNotifications.length}
								</span>
							</Typography>
							<div className="pr-16">
								<IconButton
									color="inherit"
									onClick={() => {
										setActiveNotificationIndex(
											prevActiveNotification => prevActiveNotification - 1
										);
									}}
									disabled={activeNotificationIndex === 0}
								>
									<KeyboardArrowLeft />
								</IconButton>
								<IconButton
									color="inherit"
									onClick={() => {
										setActiveNotificationIndex(
											prevActiveNotification => prevActiveNotification + 1
										);
									}}
									disabled={activeNotificationIndex === unacknowledgedNotifications.length - 1}
								>
									<KeyboardArrowRight />
								</IconButton>
							</div>
						</div>
					</div>
					<Box display="flex" justifyContent="space-between">
						<Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
							{/* <TLog
								log={{
									// @ts-ignore
									messageKey: unacknowledgedNotifications[activeNotification].message,
									// @ts-ignore
									info: unacknowledgedNotifications[activeNotification].Info
								}}
							/> */}
							{tI(activeNotification.messageKey, activeNotification.info)}
						</Typography>
						{/* <Button className="ml-16" color="inherit" onClick={saveAcknowledgedNotifications}>
							{t('notification:dismiss')}
						</Button> */}
					</Box>
					<div className={classes.closeButtonWrapper}>
						<IconButton onClick={handleClose} color="inherit">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default Notification;
