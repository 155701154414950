/* eslint-disable import/no-cycle */
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { JobNameGroup, JobNameGroupsState, JobNameGroupsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: JobNameGroupsState = {};

const jobNameGroupsReducer = (state = initialState, action: JobNameGroupsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { jobNameGroups }
			} = action.payload;

			let updatedGroups;
			if (jobNameGroups) {
				// maintain order since not returned directly by api call
				const groups = { ...state[licenseGroupId]?.byId } || {};

				const existingGroups = Object.keys(jobNameGroups)
					.filter(id => groups[id] && typeof groups[id]?.groupOrder === 'number')
					.reduce((result: Record<string, JobNameGroup>, id) => {
						const existingGroup = groups[id];
						result[id] = {
							...jobNameGroups[id],
							groupOrder: existingGroup.groupOrder
						};
						return result;
					}, {});

				const existingGroupOrders = Object.values(existingGroups)
					.filter((group: JobNameGroup) => typeof group.groupOrder === 'number')
					.map((group: JobNameGroup) => group.groupOrder as number);
				const maxGroupOrder = existingGroupOrders.length > 0 ? Math.max(...existingGroupOrders) : -1;

				// Assign groupOrder to the rest of the groups
				const newGroups = Object.keys(jobNameGroups)
					.filter(id => !groups[id] || typeof groups[id]?.groupOrder !== 'number')
					.reduce((result: Record<string, JobNameGroup>, id, index) => {
						result[id] = {
							...jobNameGroups[id],
							groupOrder: maxGroupOrder + 1 + index
						};
						return result;
					}, {});

				updatedGroups = {
					...existingGroups,
					...newGroups
				};
			}
			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(updatedGroups && { byId: updatedGroups })
				}
			};
		}
		// case Actions.GET_DEVICE_GROUP_ORDER_SUCCESS: {
		// 	const { order, licenseGroupId } = action.payload;
		// 	const groups = { ...state[licenseGroupId].byId };

		// 	// order list may not contain all groups
		// 	const existingGroupIds = order.filter((groupId: string) => groups[groupId]);
		// 	const updatedGroups: Record<string, JobNameGroup> = {};

		// 	existingGroupIds.forEach((groupId: string, idx: number) => {
		// 		const group = { ...groups[groupId] };
		// 		group.groupOrder = idx + 1;
		// 		updatedGroups[groupId] = group;
		// 	});

		// 	// assign other group order for non existing groups in order array
		// 	const nonOrderedGroups = Object.keys(groups).filter(groupId => !existingGroupIds.includes(groupId));

		// 	nonOrderedGroups.forEach((groupId, idx) => {
		// 		const group = { ...groups[groupId] };
		// 		updatedGroups[groupId] = group;
		// 	});

		// 	return {
		// 		...state,
		// 		[licenseGroupId]: {
		// 			byId: updatedGroups
		// 		}
		// 	};
		// }
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'jobNameGroups',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	jobNameGroupsReducer
);

// Selectors
export const getJobNameGroupsById = ({ app: { selectedLicenseGroupId }, jobNameGroups }: RootState) =>
	jobNameGroups[selectedLicenseGroupId].byId;

export const getJobNameGroups = createSelector([getJobNameGroupsById], deviceGroupsById =>
	Object.values(deviceGroupsById)
);

export const getJobNameGroupById = (jobNameGroupId: JobNameGroup['id']) => (state: RootState) =>
	getJobNameGroupsById(state)[jobNameGroupId];
