/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { getFormsUrl, responseError, responseErrors } from 'app/utils/helpers';

import { getSelectedLicenseGroupId, getProfile } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { FormGroup } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_FORM_GROUP_ORDER_SUCCESS = 'GET_FORM_GROUP_ORDER_SUCCESS';
export const UPDATE_FORM_GROUP_ORDER_SUCCESS = 'UPDATE_FORM_GROUP_ORDER_SUCCESS';

export const createFormGroup = ({ title, sortOrder }: { title: string; sortOrder: number }): AppThunk => async (
	dispatch,
	getState
) => {
	// const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		// tenantId: licenseGroupId,
		title,
		sortOrder
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const response = await axios.post(`${formsApiUrl}/api/groups`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('formgroup:create:fail', 'warning'));
		} else {
			dispatch(appActions.alert('formgroup:create:success', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['form groups']));
	} catch (error) {
		dispatch(appActions.handleError(error, 'formgroup:create:fail'));
	}
};

export const editFormGroup = ({ id, title }: { id: FormGroup['id']; title: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const data = {
		title
	};
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		await axios.patch(`${formsApiUrl}/api/groups/${id}`, data);
		dispatch(appActions.alert('formgroup:edit:success', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['form groups']));
	} catch (error) {
		dispatch(appActions.alert('formgroup:edit:fail', 'error'));
	}
};

export const deleteFormGroup = (formGroupId: FormGroup['id']): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	try {
		await axios.delete(`${formsApiUrl}/api/groups/${formGroupId}`);
		dispatch(appActions.alert('formgroup:delete:success', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['forms', 'form groups']));
	} catch (error) {
		dispatch(appActions.alert('formgroup:delete:fail', 'error'));
	}
};

export const assignPolicyToFormGroups = (
	formGroupIds: FormGroup['id'][],
	policyId: string | undefined
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await Promise.all(
			_.chunk(formGroupIds, 25).map(chunk =>
				axios.patch(
					`/api/v1/formgroup/${licenseGroupId}`,
					chunk.map(formGroupId => ({ formGroupId, policyId: policyId ?? null }))
				)
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to assign policy to some form groups', 'warning'));
		} else {
			dispatch(appActions.alert('policy assigned', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['form groups']));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getFormGroupOrder = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/form`);

		dispatch({
			type: GET_FORM_GROUP_ORDER_SUCCESS,
			payload: {
				order: groupOrder,
				licenseGroupId
			}
		});
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateFormGroupOrder = (groupIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'form',
		order: groupIds
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};
