import React from 'react';

const WorkflowsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/workflows',
			exact: true,
			component: React.lazy(() => import('./WorkflowsPage'))
		}
	]
};

export default WorkflowsPageConfig;
