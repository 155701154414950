import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LogsState, LogsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: LogsState = {};

const logsReducer = (state = initialState, action: LogsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { logs }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(logs && { byId: logs })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'logs',
		storage: localForage
	},
	logsReducer
);

// Selectors
export const getLogsById = ({ app: { selectedLicenseGroupId }, logs }: RootState) => logs[selectedLicenseGroupId].byId;

export const getLogs = createSelector([getLogsById], logsById => Object.values(logsById));
