import _ from '@lodash';
import axios from 'app/client';
import { AppThunk } from 'app/store';
import { navFn, prepNavFn } from 'app/store/actions';
import { getProfile, getSelectedLicenseGroupId, getWorkflowGroupsById, getWorkflows } from 'app/store/reducers';
import { User, WorkflowGroup } from 'app/store/types';
import { getWfxUrl, responseError, responseErrors } from 'app/utils/helpers';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_WORKFLOW_GROUP_ORDER_SUCCESS = 'GET_WORKFLOW_GROUP_ORDER_SUCCESS';
export const UPDATE_WORKFLOW_GROUP_ORDER_SUCCESS = 'UPDATE_WORKFLOW_GROUP_ORDER_SUCCESS';

export const createWorkflowGroup = ({ name }: { name: string }): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const yPos = prepNavFn();

	const data = {
		name
	};

	try {
		const response = await axios.put(`${wfxApiUrl}/api/groups`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to create workflow group', 'warning'));
		} else {
			dispatch(appActions.alert('workflow group created', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflow groups'], () => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editWorkflowGroup = (
	{ id, name, modified }: { id: WorkflowGroup['id']; name: string; modified: string },
	successFn?: () => void
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const yPos = prepNavFn();

	const data = {
		name,
		id,
		modified
	};

	try {
		const response = await axios.put(`${wfxApiUrl}/api/groups`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update workflow group', 'warning'));
		} else {
			if (successFn) successFn();
			dispatch(appActions.alert('workflow group updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflow groups'], () => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const deleteWorkflowGroup = (workflowGroupId: WorkflowGroup['id']): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const workflows = getWorkflows(getState()).filter(workflow => workflow.workflowGroupId === workflowGroupId);
	const deleteAll = !workflows.some(workflow => ['running', 'paused'].includes(workflow.status));
	const stoppedWorkflows = workflows
		.filter(workflow => !['running', 'paused'].includes(workflow.status))
		.map(workflow => workflow.id);

	const yPos = prepNavFn();

	try {
		const responses = await Promise.all(
			stoppedWorkflows.map(workflowId => axios.delete(`${wfxApiUrl}/api/wfx/${workflowId}`))
		);
		if (responseErrors(responses).length || !deleteAll) {
			dispatch(appActions.alert('failed to delete workflows and workflow group', 'warning'));
		} else {
			const response = await axios.delete(`${wfxApiUrl}/api/groups/${workflowGroupId}`);
			// @ts-ignore
			if (responseError(response)) {
				dispatch(appActions.alert('failed to delete workflow group', 'warning'));
			} else {
				dispatch(appActions.alert('workflow group deleted', 'success'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflows', 'workflow groups'], () => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const assignPolicyToWorkflowGroups = (
	workflowGroupIds: WorkflowGroup['id'][],
	policyId: string | undefined
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const yPos = prepNavFn();

	try {
		const responses = await Promise.all(
			_.chunk(workflowGroupIds, 25).map(chunk =>
				axios.patch(
					`/api/v1/workflowgroup/${licenseGroupId}`,
					chunk.map(workflowGroupId => ({ workflowGroupId, policyId: policyId ?? null }))
				)
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to assign policy to some workflow groups', 'warning'));
		} else {
			dispatch(appActions.alert('policy assigned', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflow groups'], () => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const changeWorkflowGroupOwner = (
	workflowGroupIds: WorkflowGroup['id'][],
	ownerId: User['id']
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const yPos = prepNavFn();
	try {
		const responses = await Promise.all(
			workflowGroupIds.map(workflowGroupId => {
				const prevWorkflowGroup = getWorkflowGroupsById(state)[workflowGroupId];
				const prevAcl = prevWorkflowGroup.acl;
				const acl = {
					...prevAcl,
					users: [
						...prevAcl.users
							// remove the new owner's old role (if they happened to have one)
							.filter(({ name }) => name !== ownerId)
							// remove the old owner
							.filter(({ role }) => role !== 'owner'),
						// add the new owner
						{
							name: ownerId,
							role: 'owner' as const
						}
					]
				};
				const body = {
					id: workflowGroupId,
					name: prevWorkflowGroup.name,
					acl
				};
				return axios.post(`${wfxApiUrl}/api/groups`, body);
			})
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to change workflow group owner', 'warning'));
		} else {
			dispatch(appActions.alert('workflow group owner updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflow groups', 'users'], () => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const shareWorkflowGroup = (
	workflowGroups: {
		id: WorkflowGroup['id'];
		name: WorkflowGroup['name'];
	}[],
	{
		users,
		userGroups,
		devices,
		deviceGroups
	}: {
		users: { [userId: string]: { role: WorkflowGroup['acl']['users'][0]['role'] } };
		userGroups: { [userGroupId: string]: { role: WorkflowGroup['acl']['groups'][0]['role'] } };
		devices: { [deviceId: string]: { role: WorkflowGroup['acl']['devices'][0]['role'] } };
		deviceGroups: { [deviceGroupId: string]: { role: WorkflowGroup['acl']['deviceGroups'][0]['role'] } };
	}
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	try {
		const responses = await Promise.all(
			workflowGroups.map(workflowGroup => {
				const prevAcl = getWorkflowGroupsById(state)[workflowGroup.id].acl;
				const acl = {
					...prevAcl,
					users: [
						...Object.entries(users).map(([userId, { role }]) => ({
							name: userId,
							role
						})),
						// also include the owner
						prevAcl.users.find(user => user.role === 'owner')!
					],
					groups: Object.entries(userGroups).map(([userGroupId, { role }]) => ({
						name: userGroupId,
						role
					})),
					devices: Object.entries(devices).map(([deviceId, { role }]) => ({
						name: deviceId,
						role
					})),
					deviceGroups: Object.entries(deviceGroups).map(([deviceGroupId, { role }]) => ({
						name: deviceGroupId,
						role
					}))
				};
				const body = {
					id: workflowGroup.id,
					name: workflowGroup.name,
					acl
				};
				return axios.post(`${wfxApiUrl}/api/groups`, body);
			})
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to share workflow group', 'warning'));
			return true;
		}
		dispatch(appActions.alert('workflow group shared', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(['workflow groups']));
		return true;
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getWorkflowGroupOrder = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data: groupOrder } = await axios.get(`/api/user/${licenseGroupId}/group-order/workflow`);

		dispatch({
			type: GET_WORKFLOW_GROUP_ORDER_SUCCESS,
			payload: {
				order: groupOrder,
				licenseGroupId
			}
		});
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

export const updateWorkflowGroupOrder = (groupIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		type: 'workflow',
		order: groupIds
	};

	try {
		await axios.patch(`/api/user/${licenseGroupId}/group-order`, data);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

// export const unshareWorkflowGroup = (
// 	workflowGroupIds: WorkflowGroup['id'][],
// 	userId: string,
// 	successFn?: () => void
// ): AppThunk => async (dispatch, getState) => {
// 	const data: any[] = [];
// 	workflowGroupIds.forEach(workflowGroupId => {
// 		data.push({ id: workflowGroupId });
// 	});
// 	const response = await axios.post(`${process.env.REACT_APP_WFX_URL}/api/acl`, data, {
// 		params: { name: userId, role: 'none', kind: 'user' }
// 	});
// 	if (responseErrors(response).length) {
// 		dispatch(appActions.alert('failed to unshare workflow group', 'warning'));
// 	} else {
// 		dispatch(appActions.alert('workflow group unshared', 'success'));
// 		if (successFn) successFn();
// 	}
// 	dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
// };
