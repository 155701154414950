import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
import { arrayify } from 'app/utils/helpers';
import { useTranslation } from 'react-i18next';
import * as styles from 'app/styles';
import LogoIcon from 'app/fuse-layouts/shared-components/LogoIcon';

const useStyles = makeStyles(theme => ({
	root: styles.messageView(theme)
}));

type Props = {
	icon?: React.ReactNode;
	title: string;
	message: string[];
	adminPage?: boolean;
	footer?: React.ReactNode;
	linkMessage?: string;
};
const MessageView = ({
	icon = undefined,
	title,
	message = [],
	adminPage = false,
	footer = undefined,
	linkMessage = undefined
}: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384">
						<CardContent className="flex flex-col items-center justify-center p-32">
							{icon ? (
								<div className="m-32">
									<Icon className="text-96" color="action">
										{icon}
									</Icon>
								</div>
							) : (
								<div>
									<LogoIcon className="w-128 m-32" />
								</div>
							)}

							<Typography variant="h5" className="text-center mb-16">
								{title}
							</Typography>

							{arrayify(message)
								.filter(Boolean)
								.map(line => (
									<Typography key={line} className="text-center mb-16 w-full" color="textSecondary">
										{line}
									</Typography>
								))}

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								{footer || (
									<Link className="font-medium" to="/">
										{linkMessage ?? t('Go home')}
									</Link>
								)}
							</div>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
};

export default MessageView;
