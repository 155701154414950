import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';
import { RootState } from 'app/store';
import { MpWorkflowsState, MpWorkflowsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: MpWorkflowsState = {
	list: undefined
};

const mpWorkflowsReducer = (state = initialState, action: MpWorkflowsActionTypes) => {
	switch (action.type) {
		case Actions.GET_MP_WORKFLOWS_SUCCESS: {
			return {
				...state,
				list: action.payload.list
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'mpWorkflows',
		storage: localForage,
		whitelist: [] // get list every site revisit
	},
	mpWorkflowsReducer
);

// Selectors
export const getMpWorkflows = ({ mpWorkflows }: RootState) => mpWorkflows.list;
export const getMpWorkflowsById = createSelector([getMpWorkflows], mpWorkflows => _.keyBy(mpWorkflows, 'id'));
