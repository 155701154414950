import _ from 'lodash';
import axios from 'app/client';
import { AxiosError } from 'axios';
import { AppThunk } from 'app/store';
import { MpDevice } from 'app/store/types';
import * as appActions from './app.actions';

const massageRawMpDevices = (rawMpDevice: any) => {
	const mpDevice: MpDevice = {
		id: rawMpDevice.id,
		serial: rawMpDevice.serial,
		name: rawMpDevice.name,
		friendlyName: rawMpDevice.friendlyName || undefined,
		family: rawMpDevice.family,
		generation: rawMpDevice.generation,
		productLine: rawMpDevice.productLine,
		ipAddress: rawMpDevice.ip,
		location: rawMpDevice.location,
		STCApp: rawMpDevice.stcApp
	};

	return mpDevice;
};

export const GET_MP_DEVICES_SUCCESS = 'GET_MP_DEVICES_SUCCESS';

export const getMpDevices = (): AppThunk => async (dispatch, getState) => {
	// const licenseGroupId = getSelectedLicenseGroupId(getState());
	// const profile = getProfile(getState());

	try {
		const { data: rawMpDevices } = await axios.get(`/api/device/user-devices`);
		const mpDevices: { [id: string]: MpDevice } = _.mapValues(rawMpDevices, massageRawMpDevices);

		const unsupportedDevices = ['958PRO'];

		const validMpDevices = _.pickBy(
			mpDevices,
			({ generation, productLine, name }) =>
				['IT5', 'IT6'].includes(generation) && productLine !== 'mfx' && !unsupportedDevices.includes(name)
		);

		dispatch({
			type: GET_MP_DEVICES_SUCCESS,
			payload: {
				list: Object.values(validMpDevices)
			}
		});
	} catch (error) {
		// HACK-ish::if the user is not a MP user - we just pretend they have no import-able devices
		if (error instanceof AxiosError && error.response?.status === 403) {
			dispatch({
				type: GET_MP_DEVICES_SUCCESS,
				payload: {
					list: []
				}
			});
			return;
		}

		dispatch(appActions.handleError(error));
	}
};
