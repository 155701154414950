import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';
import { RootState } from 'app/store';
import { MpFormsState, MpFormsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: MpFormsState = {
	list: undefined
};

const mpFormsReducer = (state = initialState, action: MpFormsActionTypes) => {
	switch (action.type) {
		case Actions.GET_MP_FORMS_SUCCESS: {
			return {
				...state,
				list: action.payload.list
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'mpForms',
		storage: localForage,
		whitelist: [] // get list every site revisit
	},
	mpFormsReducer
);

// Selectors
export const getMpForms = ({ mpForms }: RootState) => mpForms.list;
export const getMpFormsById = createSelector([getMpForms], mpForms => _.keyBy(mpForms, 'id'));
