import React from 'react';

const AboutStratusPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/about-dispatcher-stratus',
			exact: true,
			component: React.lazy(() => import('./AboutStratusPage'))
		}
	]
};

export default AboutStratusPageConfig;
