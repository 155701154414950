import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';
import { RootState } from 'app/store';
import { SampleWorkflowsState, SampleWorkflowsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: SampleWorkflowsState = {};

const sampleWorkflowsReducer = (state = initialState, action: SampleWorkflowsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { sampleWorkflows }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(sampleWorkflows && {
						byId: _.mapValues(sampleWorkflows, sampleWorkflow => ({
							...sampleWorkflow
						}))
					})
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'sampleWorkflows',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	sampleWorkflowsReducer
);

// Selectors
export const getSampleWorkflowsById = ({ app: { selectedLicenseGroupId }, sampleWorkflows }: RootState) =>
	sampleWorkflows[selectedLicenseGroupId].byId;

export const getSampleWorkflows = createSelector([getSampleWorkflowsById], sampleWorkflowsById =>
	Object.values(sampleWorkflowsById)
);
