import React from 'react';

const JobQueueManagementPageConfig: any = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/home/job-queue/job-queue-management',
			exact: true,
			component: React.lazy(() => import('./JobQueueManagementPage'))
		}
	]
};

export default JobQueueManagementPageConfig;
