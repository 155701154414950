import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import reducer from 'app/store/reducers';
import { ThunkAction } from 'redux-thunk';

const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredPaths: ['fuse.settings', 'fuse.navigation'],
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
		}
	})
});

const persistor = persistStore(store);

export default store;

export { persistor };

// types
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
