import React from 'react';
import { useSelector } from 'app/modules/react-redux';
import { getProfile, getManagedLicenseGroups, getUserPreferences } from 'app/store/reducers';
import { Redirect } from 'react-router-dom';
import useGetPageSection from 'app/hooks/useGetPageSection';

const Root = () => {
	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const managedLicenseGroups = useSelector(getManagedLicenseGroups) as
		| ReturnType<typeof getManagedLicenseGroups>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const pageSection = useGetPageSection();
	const { startPage } = useSelector(getUserPreferences);

	const startPagePath = {
		home: 'home',
		inbox: 'home/inbox',
		jobs: 'home/jobs',
		'job-queue': 'home/job-queue',
		tracker: 'home/tracker',
		dashboard: 'dashboard',
		apps: 'apps',
		workflows: 'workflows',
		devices: 'devices',
		users: 'users',
		forms: 'forms',
		logs: 'logs',
		roles: 'roles'
	} as Record<typeof startPage, string>;

	if (pageSection.licenseGroupDomain) {
		return <Redirect to={`/admin/${startPagePath[startPage]}`} />;
	}

	if (profile && managedLicenseGroups && managedLicenseGroups.length >= 1) {
		return <Redirect to="/manager/tenants" />;
	}

	return <Redirect to="/welcome" />;
};

export default Root;
