import helpContextDefinitions from './helpContextDefintions';

type HelpContext = {
	stratusPath: string;
	helpUrl: string;
};

function getHelpContextUrl(stratusPath: string) {
	if (helpContextDefinitions.length === 0) {
		return '';
	}

	let returnVal = '';

	helpContextDefinitions.forEach((helpContext: HelpContext) => {
		if (stratusPath === helpContext.stratusPath.trim()) {
			returnVal = helpContext.helpUrl.trim();
		}

		if (stratusPath.startsWith(helpContext.stratusPath.trim())) {
			returnVal = helpContext.helpUrl.trim();
		}
	});

	return returnVal;
}

export default getHelpContextUrl;
