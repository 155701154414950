import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { PeopleState, PeopleActionTypes } from 'app/store/types';
import { createSelector } from '@reduxjs/toolkit';
import { groupByID } from 'app/utils/helpers';
import * as Actions from '../actions';
import { RootState } from '..';

const initialState: PeopleState = {
	jobs: {},
	queues: {},
	tenantQueues: {},
	queueJobs: {},
	selectedJob: null,
	inbox: {},
	tracker: {},
	dropData: {}
};

const peopleReducer = (state = initialState, action: PeopleActionTypes) => {
	switch (action.type) {
		case Actions.GET_USER_JOBS_SUCCESS: {
			return {
				...state,
				jobs: action.payload
			};
		}
		case Actions.GET_SELECTED_JOB_SUCCESS: {
			return {
				...state,
				selectedJob: action.payload
			};
		}
		case Actions.RESET_SELECTED_JOB_SUCCESS: {
			return {
				...state,
				selectedJob: null
			};
		}
		case Actions.GET_USER_QUEUES_SUCCESS: {
			return {
				...state,
				queues: {
					...action.payload
				}
			};
		}
		case Actions.GET_TENANT_QUEUES_SUCCESS: {
			return {
				...state,
				tenantQueues: {
					...action.payload
				}
			};
		}
		case Actions.GET_QUEUE_JOBS_SUCCESS: {
			return {
				...state,
				queueJobs: {
					...state.queueJobs,
					[action.payload.id]: action.payload.jobs
				}
			};
		}
		case Actions.GET_USER_INBOX_SUCCESS: {
			return {
				...state,
				inbox: action.payload
			};
		}
		case Actions.ACCEPT_JOB_SUCCESS: {
			return {
				...state,
				...(state.selectedJob && {
					selectedJob: {
						...state.selectedJob,
						accepted: true
					}
				})
			};
		}
		case Actions.GET_TRACKER_SUCCESS: {
			return {
				...state,
				tracker: action.payload
			};
		}
		case Actions.TRACK_JOB_SUCCESS: {
			return {
				...state,
				tracker: action.payload
			};
		}
		case Actions.UNTRACK_JOB_SUCCESS: {
			return {
				...state,
				tracker: action.payload
			};
		}
		case Actions.GET_DROP_DATA_SUCCESS: {
			return {
				...state,
				dropData: action.payload
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

// reducer
// const selectedFormReducer = (state: { [licenseGroupId: string]: Form } = {}, action: any) => {
// 	switch (action.type) {
// 		case GET_SELECTED_FORM_SUCCESS:
// 			return {
// 				...state,
// 				[action.payload.licenseGroupId]: action.payload.form
// 			};
// 		// ... other cases ...
// 		default:
// 			return state;
// 	}
// };

export default persistReducer(
	{
		key: 'people',
		storage: localForage
	},
	peopleReducer
);

// Selectors
const getUserJobs = ({ people }: RootState) => people.jobs;
const getSelectedJob = ({ people }: RootState) => people.selectedJob;
const getVisibleJobs = ({ people }: RootState) => ({
	...people.jobs,
	...Object.values(people.queueJobs).reduce(
		(prev, queue) => ({
			...prev,
			...queue
		}),
		{}
	)
});
const getUserQueues = ({ people }: RootState) => people.queues;
const getTenantQueues = ({ people }: RootState) => people.tenantQueues;
const getQueueJobs = ({ people }: RootState) => people.queueJobs;
const getUserInbox = ({ people }: RootState) => people.inbox;
const getJobTracker = ({ people }: RootState) => people.tracker;
const getDropData = ({ people }: RootState) => people.dropData;
export const getUserJobsSelector = createSelector([getUserJobs], userJobs => userJobs);
export const getSelectedJobSelector = createSelector([getSelectedJob], selectedJob => selectedJob);
export const getVisibleJobsSelector = createSelector([getVisibleJobs], visibleJobs => visibleJobs);
export const getUserQueuesSelector = createSelector([getUserQueues], queues => queues);
export const getActiveUserQueuesSelector = createSelector([getUserQueues], queues =>
	groupByID(Object.values(queues).filter(q => q.active))
);
export const getTenantQueuesSelector = createSelector([getTenantQueues], tenantQueues => tenantQueues);
export const getSelectedQueueJobs = (id: string) => createSelector([getQueueJobs], queues => queues[id]);
export const getUserInboxSelector = createSelector([getUserInbox], inbox => inbox);
export const getTrackedJobs = createSelector([getJobTracker], tracker => tracker);
export const getIsJobTracked = (id: string) => createSelector([getJobTracker], tracker => tracker[id] !== undefined);
export const getDropDataSelector = createSelector([getDropData], dropData => dropData);
