import React from 'react';

export const AdminContactUsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/contact-us',
			exact: true,
			component: React.lazy(() => import('./ContactUsPage'))
		}
	]
};

export const PublicContactUsPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/contact-us',
			exact: true,
			component: React.lazy(() => import('./ContactUsPage'))
		}
	]
};
