import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useDispatch } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';

function QuickPanelToggleButton(props) {
	const dispatch = useDispatch();

	return (
		<IconButton className="w-64 h-64" onClick={_ev => dispatch(Actions.toggleQuickPanel())}>
			{props.children}
		</IconButton>
	);
}

QuickPanelToggleButton.defaultProps = {
	children: <Icon>format_list_bulleted</Icon>
};

export default QuickPanelToggleButton;
