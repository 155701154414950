import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PurgeStateWrapper from 'app/components/PurgeStateWrapper';
import React from 'react';
import { useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';
import { getIsPossibleDevelopStore } from 'app/store/reducers';
import { getHelpUrl } from 'app/utils/helpers';

function FooterLayoutAdmin(_props) {
	const { t, i18n } = useTranslation();
	const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className="relative z-10"
				color="default"
				style={{ backgroundColor: footerTheme.palette.background.default }}
			>
				<Toolbar className="flex items-center container py-0 px-16 lg:px-24">
					<div className="flex flex-1">
						<Typography>
							<PurgeStateWrapper>© 2021 Konica Minolta, Inc.</PurgeStateWrapper>
						</Typography>
					</div>

					<div>
						<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
							<Link
								color="inherit"
								href={getHelpUrl(i18n.language)}
								target="_blank"
								rel="noreferrer noopener"
							>
								{t('Help')}
							</Link>
							{/* TODO::add a contact page - maybe with a phone number as well? */}
							{!isPossibleDevelopStore && (
								<Link
									color="inherit"
									href="https://konicaminoltamarketplace.com/support/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									{t('Contact Us')}
								</Link>
							)}
							<Link
								color="inherit"
								href="https://kmbs.konicaminolta.us/privacy-policy"
								target="_blank"
								rel="noreferrer noopener"
							>
								{t('Privacy')}
							</Link>
							<RouterLink to="/terms">{t('Terms and Conditions')}</RouterLink>
						</Breadcrumbs>
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(FooterLayoutAdmin);
