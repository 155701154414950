import { fuseDark } from '@fuse/colors';
import _ from '@lodash';
import { lightBlue, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { SortLabelIcon } from 'app/components/Icons';
import qs from 'qs';

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
	customScrollbars: true,
	animations: true,
	direction: 'ltr',
	theme: {
		main: 'default',
		navbar: 'mainThemeDark',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeDark'
	}
};

export function getParsedQuerySettings() {
	const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

	if (parsedQueryString && parsedQueryString.defaultSettings) {
		return JSON.parse(parsedQueryString.defaultSettings);
	}
	return {};

	// Generating route params from settings
	/* const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings); */
}

/**
 * THEME DEFAULTS
 */
// DEV NOTE::add your custom theme rules here
export function mustHaveThemeOptions(themeObj) {
	const theme = createMuiTheme(themeObj);
	return {
		typography: {
			fontFamily: ["'Open Sans'", 'Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 600,
			useNextVariants: true,
			suppressDeprecationWarnings: true,
			htmlFontSize: 10,
			body1: {
				fontSize: '1.4rem'
			},
			body2: {
				fontSize: '1.4rem'
			},
			button: {
				textTransform: 'none'
			}
		},
		props: {
			MuiTableSortLabel: {
				IconComponent: SortLabelIcon
			},
			MuiSelect: {
				IconComponent: ExpandMore
			},
			MuiPaper: {
				elevation: 8
			}
		},
		overrides: {
			MuiTableSortLabel: {
				icon: {
					fill: '#A48ADB',
					fontSize: '24px'
				}
			},
			MuiTooltip: {
				tooltip: {
					backgroundColor: '#663399',
					fontFamily: 'Open Sans',
					fontSize: 12,
					color: '#ffffff',
					opacity: '0.87 !important',
					borderRadius: 4,
					whiteSpace: 'pre-wrap'
				}
			},
			MuiIconButton: {
				root: {
					color: '#7D61BC',
					borderRadius: 8,
					// use `where:()` to lower specificity
					':where([data-tier="enterprise"] .icon-button-text-inherit-wrapper) &': {
						color: 'inherit'
					}
				}
			},
			// keep selected days rounded on calendar
			MuiPickersBasePicker: {
				container: {
					'& .MuiIconButton-root': {
						borderRadius: '50%'
					}
				}
			},
			MuiCheckbox: {
				root: {
					'&:hover': {
						color: theme.palette.secondary.main
					}
				},
				indeterminate: {
					color: theme.palette.type === 'dark' ? '#FFFFFF' : theme.palette.secondary.main
				}
			},
			MuiInputLabel: {
				root: {
					'&.Mui-focused': {
						color: 'currentColor'
					}
				}
			},
			MuiTabs: {
				indicator: {
					color: theme.palette.type === 'dark' ? '#FFFFFF' : theme.palette.secondary.main,
					backgroundColor: theme.palette.type === 'dark' ? '#FFFFFF' : theme.palette.secondary.main
				}
			},
			MuiTableRow: {
				root: {
					height: '3.2rem !important',
					'& td, th': {
						padding: '4px 16px'
					}
				}
			},
			MuiDialog: {
				container: {
					background: 'rgba(38, 38, 38, 0.39)',
					'[data-tier="enterprise"] & .MuiAppBar-colorPrimary': {
						backgroundColor: theme.palette.type === 'dark' ? '#515151' : '#E3E3E3',
						color: theme.palette.text.primary
					}
				}
			}
		}
	};
}

export const defaultThemes = {
	default: {
		palette: {
			type: 'light',
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700]
			},
			error: red
		},
		status: {
			danger: 'orange'
		}
	},
	defaultDark: {
		palette: {
			type: 'dark',
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700]
			},
			error: red
		},
		status: {
			danger: 'orange'
		}
	}
};

export function extendThemeWithMixins(obj) {
	const theme = createMuiTheme(obj);
	return {
		border: (width = 1) => ({
			borderWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderLeft: (width = 1) => ({
			borderLeftWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderRight: (width = 1) => ({
			borderRightWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderTop: (width = 1) => ({
			borderTopWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		}),
		borderBottom: (width = 1) => ({
			borderBottomWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider
		})
	};
}

export function mainThemeVariations(theme) {
	const mainThemeDark = _.merge({}, theme, {
		palette: {
			type: 'dark',
			background: {
				paper: '#323232',
				default: '#232323'
			}
		}
	});
	const mainThemeLight = _.merge({}, theme, {
		palette: {
			type: 'light',
			background: {
				paper: '#FFFFFF',
				default: '#F7F7F7'
			}
		}
	});

	return {
		mainThemeDark: createMuiTheme(_.merge({}, mainThemeDark, mustHaveThemeOptions(mainThemeDark))),
		mainThemeLight: createMuiTheme(_.merge({}, mainThemeLight, mustHaveThemeOptions(mainThemeLight)))
	};
}
