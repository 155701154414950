/* eslint-disable import/no-cycle */
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { JobNamesState, JobNamesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: JobNamesState = {};

const jobNamesReducer = (state = initialState, action: JobNamesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { jobNames }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					...state[licenseGroupId],
					...(jobNames && { byId: jobNames })
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'jobNames',
		storage: localForage,
		whitelist: localStorage.getItem('persist') ? undefined : []
	},
	jobNamesReducer
);

// Selectors
export const getJobNamesById = ({ app: { selectedLicenseGroupId }, jobNames }: RootState) =>
	jobNames[selectedLicenseGroupId].byId;

export const getJobNames = createSelector([getJobNamesById], jobNamesById => Object.values(jobNamesById));
