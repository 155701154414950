import React from 'react';

const ConfirmManagerErrorPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/confirm-manager/error',
			exact: true,
			component: React.lazy(() => import('./ConfirmManagerErrorPage'))
		}
	]
};

export default ConfirmManagerErrorPageConfig;
